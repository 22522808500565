import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import {getData} from '../../services/index';
import { Card, Spin } from 'antd';

let data: any[];
function Personalinfo() {
    const [Sdata, setData] = useState([]);
    const {id} = useParams();
    const [std_gender, setGender] = useState("");
    const [std_dob, setDOB] = useState("");
    const [std_fname, setFname] = useState("");
    const [std_mobile, setMobile] = useState("");
    const [std_wappno, setStdWappno] = useState("");
    const [std_fmobile, setFmobile] = useState("");
    const [wapp_no, setFwapp_no] = useState("");
    const [std_email, setEmail] = useState("");
    const [address, setAddress] = useState("");
   
    


    const loadStdPersonalData = ()=>{

        getData('getstudentpersonalinfo/'+ atob(String(id))).then((res)=>{
          setGender(res.data['std_gender'])
          setDOB(res.data['std_dob'])
          setFname(res.data['std_fname'])
          setMobile(res.data['std_mobile'])
          setStdWappno(res.data['std_wappno'])
          setFmobile(res.data['std_fmobile'])
          setFwapp_no(res.data['wapp_no'])
          setEmail(res.data['std_email'])
          setAddress(res.data['address'])
        })
       
        //console.log(JSON.parse(JSON.stringify(data))) 
    }

    useEffect(()=>{
      //  alert(id);
        loadStdPersonalData(); 
       },[]);

  return (
    <div className='Personalinfo'>
        
              <div className="row">
               
                  <div className="col">
                    <Card className='font-style' title="Student Info"  > 
                       <table  className="table table-striped ng-star-inserted">
                          <tbody>
                            <tr>
                              <td>Gender</td>
                              <td>{std_gender}</td>
                            </tr>
                            <tr>
                              <td>Date of Birth</td>
                              <td>{std_dob}</td>
                            </tr>
                            <tr>
                              <td>Father's Name</td>
                              <td>{std_fname}</td>
                            </tr>
                          </tbody>
                       </table>
                    </Card>
                  </div>
                  <div className="col">
                    <Card className='font-style' title="Contact Details"  > 
                    <table  className="table table-striped ng-star-inserted">
                          <tbody>
                            <tr>
                              <td>Mobile Number</td>
                              <td>{std_mobile} { std_wappno == '1' ? "(W)" : ''}</td>
                            </tr>
                            <tr>
                              <td>Mobile Number(G)</td>
                              <td>{std_fmobile} { wapp_no == '1' ? "(W)" : ''}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{std_email} </td>
                            </tr>
                            <tr>
                              <td>Address</td>
                              <td>{address}</td>
                            </tr>
                            
                          </tbody>
                       </table>
                    </Card>
                  </div>
              </div>
           
        
    </div>
  )
}

export default Personalinfo