import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useParams,useLocation } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card, Form, Input, Modal, Spin, Table, Tabs, Tag, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Meta from 'antd/es/card/Meta';
import type { TabsProps } from 'antd';
import Personalinfo from './ProfileComp/Personalinfo';
import Coursedetails from './ProfileComp/Coursedetails';
import Marksheet from './ProfileComp/Marksheet';
import Feecard from './ProfileComp/Feecard';
import Attendance from './ProfileComp/Attendance';
import Installments from './Installments';

import { img_url } from '../services/index';
import  Certificates  from './Certificates';



const items: TabsProps['items'] = [
    {
        key: '1',
        label: 'Personal Info',
        children: <Personalinfo />,
    },
    {
        key: '2',
        label: 'Course Details',
        children: <Coursedetails />,
    },
    {
        key: '3',
        label: 'Attendance',
        children: <Attendance />,
    },
    {
        key: '4',
        label: 'Marks',
        children: <Marksheet />,
    },
    {
        key: '5',
        label: 'Fee Card',
        children: <Feecard />,
    },
    {
        key: '6',
        label: 'Payments',
        children: <Installments/>,
    },
    /*{
        key: '7',
        label: 'CRF',
        children: 'Content of Tab Pane 3',
    },*/
    {
        key: '8',
        label: 'Certificate',
        children: <Certificates />,
    },
  ];

function Studentprofile() {
    const onChange = (key: string) => {
        //console.log(key);
      };
      const [loading, setLoading] = useState(false);
      const {id} = useParams();
      const {name} = useParams();
      const {img} = useParams();
      const {cname} = useParams();

      
     
  return (
    <div className='Studentprofile noprint'>
        <div className="content-header mt-5">
            <div className="container-fluid">
               
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Student Profile</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className='content'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                            <Card
                            style={{ width: '100%' }}
                            cover={
                            <img
                                height={300}
                                alt="example"
                                src="https://aranyakvalley.com/assets/logos/banner2.jpg"
                            />
                            }
                            
                            >
                            <div className="row">
                                <div className="col">
                                    <img  id="profile_img" width={200} height={200}  className="img-thumbnail" style={{marginTop: '-100px'}} src={img_url + atob(String(img))} />

                                </div>
                                <div className="col">
                                    <h3 >{atob(String(id))}</h3>
                                    <h4 >{atob(String(cname))}</h4>
                                    <h4 >{atob(String(name))}</h4>
                                </div>
                            </div>
                        

                        
                        </Card>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                    <Card className='font-style' title="Student Detail" extra={<><a href={"https://avksladmin.aranyakvalley.com/#/editstudentdetails/" +  id} target='_blank' className='mr-2' ><Button type="primary" >Edit Details</Button></a><a href={"https://avksladmin.aranyakvalley.com/backend/public/idcard/html2img.php?std_id="+atob(String(id))} target='_blank'><Button type="primary" className='mr-2' >Generate ID</Button></a><a href={"https://avksladmin.aranyakvalley.com/backend/printcrf/" + atob(String(id))} target='_blank'  ><Button type="primary" >Print CRF</Button></a></>} >
                        <Spin tip="Loading" size="large" spinning={loading}> 
                        <Tabs
                            onChange={onChange}
                            type="card"
                            items={items}
                        />
                        </Spin>
                        
                    </Card>
                    </div>
                </div>
            </div>
          </section>
          <br></br>
    </div>
  )
}

export default Studentprofile