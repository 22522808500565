import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useLocation } from 'react-router-dom';
import {getPagePer} from '../../services/index';
import {getData} from '../../services/index';
import {postData} from '../../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { img_url } from '../../services/index';


type FieldType = {
    centre?: string;
    stdlist?:string;
  }

  let centre_code:any;
  let adm_type:any;
  let centre_name:any;
  let staff_code:any;
  let user_id:any;
  let std_id:any;

interface DataType {
    sid : number;
    std_id?: string;
    std_name?: string;
    std_img?: string;
    course_name?: string;
    centre_name?:string;
  }

  

function Crflist() {
    const [data,setData] = useState();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [centreData, setCentreData] = useState([]);

    const getCrfData = (cid:any) =>{
        setLoading(true);
        getData('getcrfdata/'+cid).then((res)=>{
            setLoading(false);
            setData(res.data);
        })
    }

    const handleCentreChange = (value: string) => {
        centre_code = value;
        getCrfData(centre_code);
        //localStorage.setItem('cid',centre_code);
    }

    const columns: ColumnsType<DataType> = [
        {
          title: 'Image',
          dataIndex: 'std_img',
          key: 'std_img',
          render: (std_img) => <img width="50" height="35" className="img-thumbnail" src={img_url+std_img} alt="" />
          
        },
        {
          title: 'Student ID',
          dataIndex: 'std_id',
          key: 'std_id',
        },
        {
            title: 'Student Name',
            dataIndex: 'std_name',
            key: 'std_name',
        },
        {
            title: 'Course Name',
            dataIndex: 'course_name',
            key: 'course_name',
        },
        
        {
          title: 'Action',
          key: 'action',
          
          width:100,
          render: (_, {std_id,std_name,std_img,centre_name}) => (
              <> 
    
                <Tooltip title="Edit Details">
                  
                <a href={"https://avksladmin.aranyakvalley.com/backend/printcrf/" + std_id} target='_blank'  ><Button type="primary" >Print CRF</Button></a>
              
                </Tooltip>
              
                
              </>
          ),
          
        },
      ];

      const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
         // console.log(centreData);
        });   
      }

      useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id; 
        loadCentre();    
        form.setFieldValue('centre',centre_code);
        //centre_code = localStorage.getItem('cid');
        getCrfData(centre_code);
      },[]);
      
  return (
    <div className='Crflist'>
        <div className="content-header mt-5">
            <div className="container-fluid">
               
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">CRF Requests</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>

          <section className='content'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                    <Card className='font-style' title="Student CRF Requests"  extra={
                        <Form form={form} layout='inline'>
                        <Form.Item<FieldType> 
                            
                            name="centre"
                            className="d-flex"
                            >
                          <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                              {
                              adm_type == 1 ? (
                                  centreData.map((item:any)=>{
                                      return(
                                                <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                            
                                          )       
                                      })
                                  ) : (
                                      <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                                  )
                              }
                          
                          </Select>
                          </Form.Item>
                        </Form>
                    } >
                        
                        <Spin tip="Loading" size="large" spinning={loading}> 
                                <Table className='font-style' columns={columns} dataSource={data} size="small" />
                            </Spin> 
                       
                        
                    </Card>
                    </div>
                </div>
            </div>
        </section>
       
    </div>
  )
}



export default Crflist