import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Input, Modal, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';


type FieldType = {
    element_name?: string;
    
  };
  
  interface DataType {
    element_id : number;
    element_name?: string;
  }


  const columns: ColumnsType<DataType> = [
    {
      title: 'Element ID',
      dataIndex: 'element_id',
      key: 'element_id',
      
    },
    {
      title: 'Element Name',
      dataIndex: 'element_name',
      key: 'element_name',
    },
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <Button type="primary" danger>Delete</Button>
      ),
      
    },
  ];

function Elements() {
    const [data, setElement] = useState();
    const [ishide, ShowHide] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {
      };
      
    

    const success = (msg:any) => {
      messageApi.open({
        type: 'success',
        content: msg,
      });
    };
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log('Success:', values);
   
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        let formData = form.getFieldsValue();
        postData('addelement',formData).then((res)=>{
        //console.log(res);
        if(res.data){
          //setMenuName("");
          success("Element Created");
          setIsModalOpen(false);
          loadData();  
        }
      })

      })
      .catch((errors) => {
        // Errors in the fields

        console.log("no");
      });
  }, [form]);
  const [messageApi, contextHolder] = message.useMessage();
  const loadData = ()=>{

    getData('getelement/').then((res)=>setElement(res.data));
  
    //console.log(svrData); 
     
  }

  useEffect(()=>{
    loadData();
   },[]);
  return (
    <div className='Elements'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Elements</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Element List" extra={<Button className={ishide? 'hidden' : ''} type='primary' onClick={showModal}>Add Element</Button>} >
                        <Table className='font-style' columns={columns} dataSource={data} />
                    </Card>
                    </div>
                </div>
              </div>
            </section>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Add New Element" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={onFormSubmit}  >
            Submit
          </Button>,
        ]}>
           
              
              <Form.Item<FieldType>
                label="Element Name"
                name="element_name"
                rules={[{ required: true, message: 'Please enter element name!' }]}
              >
                <Input />
              </Form.Item>

        </Modal>
        </Form>
    </div>
  )
}

export default Elements