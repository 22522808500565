import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useLocation } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Input, Modal, Select, Spin, Table, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';

import form from 'antd/es/form';
import {errorMsg, getPagePer,successMsg} from '../services/index';
import './style.css';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let std_id:any;
let user_id:any;
let options: any=[];
let course_id_edit:any;
let  select_cert:any=[];

interface DataType {
    centre_id : number;
    centre_code?: string;
    centre_name?: string;
    centre_email?: string;
    centre_num?: string;
    centre_status?: string;
  }
function Centres() {
    const [data, setElement] = useState();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [showAddBtn, setAddBtn] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };

      const columns: ColumnsType<DataType> = [
        {
          title: 'Centre Id',
          dataIndex: 'centre_id',
          key: 'centre_id',
          
        },
        {
          title: 'Centre Code',
          dataIndex: 'centre_code',
          key: 'centre_code',
        },
        {
            title: 'Centre Name',
            dataIndex: 'centre_name',
            key: 'centre_name',
        },
        {
          title: 'Centre Email',
          dataIndex: 'centre_email',
          key: 'centre_email',
        },
        {
            title: 'Centre Number',
            dataIndex: 'centre_num',
            key: 'centre_num',
        },
        {
            title: 'Centre Status',
            dataIndex: 'centre_status',
            key: 'centre_status',
            render: (centre_status) => centre_status == 1 ? <Tag color="success">Active</Tag> : <Tag color="error">In-Active</Tag>
    
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, {centre_id}) => (
              <><Button type="primary" danger size='small'><i className="fas fa-ban"></i></Button>
              <Button className={''}  type="primary" size='small' ><i className='fas fa-edit'></i></Button>
                
              </>
          ),
          
        },
      ];

      const getCentreList = () =>{
        setLoading(true);
        getData('getcentres').then((res)=>{
            setLoading(false);
            setElement(res.data); 
        })
      }

      useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id;
       
        /*getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res)=>{
          for(let i=0;i<res.data.length;i++){
            switch(parseInt(res.data[i].per_element_id)){
              case 2:
                    setAddBtn(true);
                    break;
              case 3:
                    setEditBtn(true);
                    break;

            }
          }
        })*/
        getCentreList();
       },[]);
  return (
    <div className='Centres'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Centres</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Course List" extra={<Button className={showAddBtn? '' : 'hidden'} type='primary' onClick={showModal}>Add New Centre</Button>} >
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={data} size="small" scroll={{ x: 1200 }} />
                    </Spin>
                    </Card>
                    </div>
                </div>
              </div>
            </section>
    </div>
  )
}

export default Centres