import React, { useEffect, useState } from 'react'
import { UploadOutlined, UserOutlined, VideoCameraOutlined,DashboardOutlined,SettingOutlined  } from '@ant-design/icons';
import { Layout, Menu, MenuProps, theme } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import {getData} from '../services/index';
import {postData} from '../services/index';
import Icon from '@ant-design/icons/lib/components/Icon';
import SubMenu from 'antd/es/menu/SubMenu';
import Header from './Header'
import Sidebar from './Sidebar';
import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import {CheckLogin, errorMsg, successMsg} from '../services/index';

let Menuitems: MenuProps['items'] = []


function AppLayout() {
    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
      } = theme.useToken();
      const [svrData, setsvrData] = useState();
      const [menuItems, setMenuItems] = useState([]);
      const loadData = ()=>{
       /* getData('getsidemenu/').then((res)=>setMenuItems(res.data));
      
        //console.log(svrData); 
        
        Menuitems = menuItems
        console.log(Menuitems);*/
      }

      useEffect(()=>{
        let login = CheckLogin();
        if(login == 1){
          
        }else{
          navigate('login');
        }
        
       },[menuItems]);
     
  return (
    <div className='AppLayout'>
          
      <Header />
      <Sidebar />
      <Footer />
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          />
    </div>
  )
}

export default AppLayout