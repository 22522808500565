import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import {getData} from '../../services/index';
import { Button, Card, Input, Spin } from 'antd';
import { img_url } from '../../services/index';
import { back_url } from '../../services/index';
import './style.css';
import Item from 'antd/es/list/Item';


let feeData:any = [];
function Feecard() {
  const {id} = useParams();
  const {img} = useParams();
  //const {id} = useParams();
  const {name} = useParams();
  const {cname} = useParams();
  //const {img} = useParams();
  const [course_fees, setCourseFee] = useState("");
  const [course_name, setCourseName] = useState("");
  const [discount, setDiscount] = useState("");
  const [total_fees, setTotalFees] = useState("");
  const [init_amount, setInitAmt] = useState("");
  const [no_of_inst, setNoInst] = useState("");
  const [day_of_month, setDayMonth] = useState("");
  const [FeeData, setFeeData] = useState([]);
  const [certi_id, setCerti] = useState([]);
  const [ishide, setIsHide] = useState(true);
  
  const loadStdCourseData = ()=>{
    getData('getstudentcoursedetails/'+ atob(String(id))).then((res)=>{
       // console.log(res.data[0]['course_name'])
       setCourseFee(res.data[0]['course_fee'])
       setCourseName(res.data[0]['course_name'])
       
      
    })
  }

  const loadStdCerti = ()=>{
    getData('getstdcertificate/'+ atob(String(id))).then((res)=>{
       // console.log(res.data[0]['course_name'])
       setCerti(res.data);
       
      
    })
  }

  const updateFees = ()=>{
    getData('updatefees/'+atob(String(id))+'/'+total_fees).then((res)=>{
      console.log(res);
    })
  }

  const handleChnage = (e:any)=>{
    let d = e;
    setDiscount(d);
    let t = parseInt(course_fees) - d;
    setTotalFees(String(t));
  }

  function generateFeeData(){
  
   /* feeData = [];
    //setFeeData(feeData);
    var today:any = new Date();
    var dd:any = today.getDate();
    var mm:any = today.getMonth() + 1;*/

    var inst_amt = parseInt(total_fees) - parseInt(init_amount);
    var inst = Math.round(inst_amt/parseInt(no_of_inst));
/*
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    } 
    today = dd + '/' + mm + '/' + yyyy;
    var mon = 12;
    var  tm;
    var install;

    feeData.push({
      "date":today,
      "part":"Initial Amount",
      "amt":init_amount
    })

    for(var i = 0; i<parseInt(no_of_inst);i++){
      if(i+1 == 1){
        install = i+1 + "st Installment";
      }else if(i+1 == 2){
          install = i+1 + "nd Installment";
      }else if(i+1 == 3){
          install = i+1 + "rd Installment";
      }else{
          install = i+1 + "th Installment";
      }

      var dt:any = new Date();
        var dd:any = parseInt(day_of_month);
        var mm:any = (dt.getMonth() + 1) + (i+1); //January is 0!
        var yyyy = dt.getFullYear() ;
        tm = mm;

        if(tm > mon){
          yyyy = dt.getFullYear() + 1;
          mm = tm - mon;
          
      }
      
      if (dd < 10) {
        dd = '0' + dd;
      } 
      if (mm < 10) {
        mm = '0' + mm;
      } 
      dt = dd + '/' + mm + '/' + yyyy;
      feeData.push({
        "date":dt,
        "part":install,
        "amt":inst

      });
    }

    //etFeeData(feeData);
    console.log(feeData)

    setIsHide(false);

   //updateFees();

    //let certi = JS(certi_id);
    */
  //  let fee = JSON.stringify(feeData);
  //updateFees();
    let certi = JSON.stringify(certi_id);

    window.open(back_url+'generatefeecard/'+atob(String(id))+'/'+atob(String(img))+'/'+atob(String(name))+'/'+course_name+'/'+certi+'/'+total_fees+'/'+day_of_month+'/'+inst_amt+'/'+inst+'/'+init_amount+'/'+no_of_inst+'/'+atob(String(cname)), '_blank');
  }

 /* const Print = () =>{     
    //console.log('print');  
    var divContents = document.getElementById('print_content')?.innerHTML;
    //console.log(divContents);
            var a = window.open('', '', 'height=1000, width=1000');
            a?.document.write('<!DOCTYPE html><html><head>');
            a?.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css"><script src="https://cdn.jsdelivr.net/npm/jquery@3.6.4/dist/jquery.slim.min.js"></script><script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script><script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js"></script>');
            a?.document.write('<style>.wrapper{border: 3px solid #333;padding: 5px;}.table_data thead{font-weight: bold;}.nb{font-weight: bold;color: red;text-align: center;}.footer{color:#ffffff;background:#1c2e4a;margin-top:200px;margin-bottom:-40px;padding:10px;text-align:center;}</style>')
            a?.document.write('</head><body>');
            a?.document.write(String(divContents));
            a?.document.write('</body></html>');
            a?.document.close();
            a?.print();
  
  }*/



  useEffect(()=>{
       
    loadStdCourseData();
    loadStdCerti(); 
   },[]);
  return (
    <div className='Feecard'>
        <div className="row noprint">
          <div className="col">
                 <Card className='font-style' title="Fee Card"  > 
                    <div className="row">
                        <div className="col-4"> 
                            <label htmlFor="">Course Fees</label><Input placeholder="" value={course_fees} readOnly disabled/>
                        </div>
                        <div className="col-4">
                            <label htmlFor="">Discount</label><Input placeholder="" value={discount} onChange={e=> handleChnage(e.target.value)}/>
                        </div>
                        <div className="col-4">
                            <label htmlFor="">Total Fees</label><Input placeholder="" value={total_fees} readOnly disabled/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <label htmlFor="">Initinal Amount</label><Input placeholder="" value={init_amount} onChange={e=> setInitAmt(e.target.value)}/>
                        </div>
                        <div className="col-4">
                            <label htmlFor="">No of Installments</label><Input placeholder="" value={no_of_inst} onChange={e=> setNoInst(e.target.value)}/>
                        </div>
                        <div className="col-4">
                            <label htmlFor="">Day of the month</label><Input placeholder="" value={day_of_month} onChange={e=> setDayMonth(e.target.value)}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col">
                        <Button className='d-block mx-auto' type='primary' onClick={generateFeeData}>Generate</Button>
                      </div>
                    </div>
                 </Card>
               </div>
        </div>
        <div className={ishide? 'hidden' : ''}>
      {/*}  <div className="row mt-2  " id='print_content'>
          <div className="col">
            <Card>
              <div className="wrapper p-2">
                  <div className="row">
                    <div className="col-12">
                        <img src="https://aranyakvalley.com/assets/logos/avksl_logo.PNG" width="200" height="150" id="logo" className='mt-2 d-block mx-auto'/>
                    </div>
                  </div>
                  <div className="row p-3">
                    <table width={'100%'} cellPadding={10}>
                      <tr>
                        <td>Student ID: <b>{atob(String(id))}</b></td>
                        <td rowSpan={4}> <img id="" src={img_url + atob(String(img))} width="100" height="100" style={{float:"right"}} /></td>
                      </tr>
                      <tr>
                        <td>Name: <b id="sname">{atob(String(name))}</b></td>
                      </tr>
                      <tr>
                        <td>Course Name: <b id="sname">{course_name}</b></td>
                      </tr>
                      <tr>
                        <td>
                          Certificates: {
                            certi_id.map((item:any)=>{
                              return(
                                <b>{item.cnt}. {item.certi_name},</b>
                              )
                            })
                          }
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="row p-3">
                    <table className='table_data'  cellSpacing={0} cellPadding={5} width={'100%'}>
                      <thead>
                        <tr>
                          <td>Installment Date</td>
                          <td>Particulars</td>
                          <td>Amount</td>
                          <td>Reciept No</td>
                          <td>Date</td>
                          <td>Remark</td>
                          <td>Sign</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          FeeData.map((item:any)=>{
                            return(
                              <tr>
                                <td contentEditable="true">{item.date}</td>
                                <td contentEditable="true">{item.part}</td>
                                <td contentEditable="true">&#8377; {item.amt}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            )
                          })
                        }
                        <tr>

                        </tr>
                        <tr>
                          <td colSpan={2}><b>Total</b></td>
                          <td contentEditable="true">
                          &#8377; {total_fees}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                            </tr>
                      </tbody>
                    </table>
                        
                  </div>
                  <div className="nb">
                          <div>
                          Please pay your installment on or before 15th day of every month. Otherwise centre will charge Rs 500 fine.
                          </div>
                        </div>

                  <div className="row">
                    <div className="col">
                    <div className="footer" >
                        <div >
                            <h4 style={{color:'white'}}> Aranyak Valley Knowledge & Skills Private Limited.</h4>
                            <span><i>1st Floor, Aranyak Urban Pride Habitat Nag Naha Lane, Shillongpatty
                                Silchar, Cachar, Assam-788004.</i></span><br />
                            <span><i>Call: 03842 224 224 </i> | <i>+91 6003883165, +91 9706538587(HQ)  Website : www.aranyakvalley.com</i></span>
                        </div>

                    </div>
                </div>
            </div>
              </div>

              
            </Card>

            
          </div>
        </div> */}
        
        </div>
    </div>
  )
}

export default Feecard