import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import {getData} from '../../services/index';
import { Button, Card, Spin } from 'antd';

function Marksheet() {
    const {id} = useParams();
    const [Sdata, setData] = useState([]);
    const [th_total_mark, setThTotalMrk] = useState("");
    const [th_total_omark, setThTotalOMrk] = useState("");
    const [pr_total_mark, setPrTotalMrk] = useState("");
    const [pr_total_omark, setPrTotalOMrk] = useState("");
    const [Total_mark, setTotalMrk] = useState("");
    const [Total_omark, setTotalOMrk] = useState("");
    const [grade, setGrade] = useState("");
    const [percent, setPercent] = useState("");



    const loadStdMarksData = ()=>{
        getData('getstudentmarks/'+ atob(String(id))).then((res)=>{
           // console.log(res.data[0]['course_name'])
            //console.log(res.data)
            setData(res.data);
            
           // console.log(res.data.length);

            let t1 = 0;
            let t2 = 0;
            let t3 = 0;
            let t4 = 0;
            let t5 = 0;
            let t6 = 0;

            for(let i=0;i<res.data.length;i++){
                t1 = t1+parseInt(res.data[i].module_fmarks);
                t2 = t2+parseInt(res.data[i].theory_mark);
                t3 = t3+parseInt(res.data[i].module_fmarks);
                t4 = t4+parseInt(res.data[i].practical_mark);
                t5 = t5+parseInt(res.data[i].total_mrk);
                t6 = t6+parseInt(res.data[i].totalO_mrk);

            }

            setThTotalMrk(String(t1));
            setThTotalOMrk(String(t2));
            setPrTotalMrk(String(t3));
            setPrTotalOMrk(String(t4));
            setTotalMrk(String(t5));
            setTotalOMrk(String(t6));

            let p = t6/t5*100;
            //console.log(p)
           let per =  parseFloat(String(p)).toFixed(2)
            //console.log(per)
            setPercent(String(per));
            //console.log(percent);

            if(parseInt(per) >= 85 ){
                setGrade('A+');
            }else if(parseInt(per) <=84 && parseInt(per) >=70 ){
                setGrade('A');
            }else if(parseInt(per) <=69 && parseInt(per) >=55){
                setGrade('B+');
            }else if(parseInt(per) <=54 && parseInt(per) >=45){
                setGrade('B');
            }else{
                setGrade('C');
            }



          
        })
    }

    

    useEffect(()=>{
       
        loadStdMarksData(); 
       },[]);


  return (
    <div className='Marksheet'>
        <div className="row">
            <div className="col" id='table'>
                <Card className='font-style' title="Marksheet"  extra={<a href={"https://avksladmin.aranyakvalley.com/#/marks/" +  id} target='_blank'><Button  type='primary' >Edit Marks</Button></a>} > 
                    <table style={{border:'2px solid'}}  width="100%" cellPadding="5"  cellSpacing="0" className='tb'>
                        <thead >
                            <tr className="ng-star-inserted">
                                <th >Module</th>
                                <th  colSpan={2}>Theory</th>
                                <th  colSpan={2}>Practical</th>
                                <th  colSpan={2}>Grand Total</th> 
                            </tr>
                            <tr  className="ng-star-inserted">
                                <th ></th>
                                <th >Total Marks</th>
                                <th >Marks Obtained</th>
                                <th >Total Marks</th>
                                <th >Marks Obtained</th>
                                <th >Total Marks</th>
                                <th >Marks Obtained</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                
                                Sdata.map((item:any)=>{
                                    return(
                                        <tr className="ng-star-inserted1">
                                            <td >{item.module_name}</td>
                                            <td >{item.module_fmarks}</td>
                                            <td >{item.theory_mark}</td>
                                            <td >{item.module_fmarks}</td>
                                            <td >{item.practical_mark}</td>
                                            <td >{item.total_mrk}</td>
                                            <td >{item.totalO_mrk}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td></td>
                                <td>{th_total_mark}</td>
                                <td>{th_total_omark}</td>
                                <td>{pr_total_mark}</td>
                                <td>{pr_total_omark}</td>
                                <td>{Total_mark}</td>
                                <td>{Total_omark}</td>
                                
                            </tr>
                            <tr>
                                <td  colSpan={4}><h4 >Grade: {grade}</h4></td>
                                <td  colSpan={4}><h4 >Percentage: {percent} %</h4></td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            </div>
        </div>
    </div>
  )
}

export default Marksheet