import { Card, Switch  } from 'antd';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import {getData} from '../services/index';
import {postData} from '../services/index';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function Permissions() {

    const [data, SetPerm] = useState([]);
    const {id} = useParams();

    const loadData = ()=>{

      getData('getuserpermission/'+id).then((res)=>SetPerm(res.data));
    
    //  console.log(data); 
       
    }

    const onChange = (checked: boolean) => {
      console.log(`switch to ${checked}`);
    };

    function UpdatePerm(user_id:any,ele_id:any,menu_id:any,sub_menu_id:any,per_id:any){
       // console.log("User: "+user_id + " elemnt_id: " + ele_id + " menu_id: "+menu_id + " sub menu: "+sub_menu_id, "per_id: " + per_id);

       let formData = new FormData();

       

       if(per_id == null){
          formData.append('per_id','');
          formData.append('user_id',user_id);
          formData.append('per_element_id',ele_id);
          formData.append('per_menu_id',menu_id);
          formData.append('per_sub_menu_id',sub_menu_id);
          
          postData('updateuserpermission',formData).then((res)=>{
            loadData();
          })
       }else{
          formData.append('per_id',per_id);
          postData('updateuserpermission',formData).then((res)=>{
            loadData();
          })
       }
      
    }

    
    useEffect(()=>{
      loadData();
     },[]);
  return (
    <div className='Permissions'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">User Permissions</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Permissions" >
                        {
                          data?.map((item:any)=>{
                            if(item.is_sub_menu == 'n'){
                              return(
                                <><div className="row">
                                  <div className="col-12">
                                    <h4>{item.menu_name}</h4>
                                    <div className="row">
                                      {item.elements.map((ele: any) => {
                                        return (
                                          <div className="col-3"><h6><Switch defaultChecked={ele.per_id != null? true:false} onChange={(event)=> UpdatePerm(id,ele.element_id,item.menu_id,'',ele.per_id)} checkedChildren={<CheckOutlined />}
                                          unCheckedChildren={<CloseOutlined />} /> {ele.element_name}</h6></div>
                                        )
                                      })}

                                    </div>
                                  </div>
                                </div><hr /></>
                              );
                            }else{
                              return(
                                <><div className="row">
                                  <div className="col-12">
                                    <h4>{item.menu_name}</h4>
                                    <div className="row">
                                      {item.children.map((child: any) => {
                                        return (
                                          <div className="col-12">
                                            <h5>{child.sub_menu_name}</h5>
                                            <div className="row">
                                            {child.elements.map((elmt: any) => {
                                              return (
                                               
                                                  <div className="col-3"><h6><Switch defaultChecked={elmt.per_id != null? true:false} checkedChildren={<CheckOutlined />}
                                                  unCheckedChildren={<CloseOutlined />} onChange={(event)=> UpdatePerm(id,elmt.element_id,item.menu_id,child.sub_menu_id,elmt.per_id)} /> {elmt.element_name}</h6></div>
                                              
                                              )
                                            })}
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div><hr /></>
                              );
                            }
                          })
                        }
                    </Card>
                    </div>
                </div>
              </div>
            </section>
    </div>
  )
}

export default Permissions