import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import {CheckLogin, errorMsg, successMsg} from '../services/index';
import {getData} from '../services/index';
import { Card, Table, Select,Input, Space, Button } from 'antd';
import {Bar,Line,Doughnut} from 'react-chartjs-2';
import {Chart, registerables, scales } from 'chart.js';
import type { SearchProps } from 'antd/es/input/Search';
import { img_url } from '../services/index';
Chart.register(...registerables)

const { Search } = Input;


let login:any;
let staff_name:any="";
let centre_code:any;
let adm_type:any;
let year: any=[];
let yrr:any = String(new Date().getFullYear());
let mm:any = '0';
let adm_count_datas: any[];

let total_stds = "";
let total_active = "";
let total_completed = "";
let total_dropout = "";
let total_crf = "";

function Dashboard() {
  const navigate = useNavigate();
  const [stdCntData, setstdCntData] = useState([]);
  const [adm_labels, setAdmLabels] = useState([]);
  const [total_adm_cnt, setTotalAdmCnt] = useState('');
  const [course_labels, setCourseLabels] = useState([]);
  const [course_count_data, setCourseCountData] = useState([]);
  const [adm_count_data, setAdmCountData] = useState([]);
  const [collect_labels, setCollectLabels] = useState([]);
  const [collect_adm_amt, setCollectAdm] = useState([]);
  const [collect_inst_amt, setCollectInst] = useState([]);
  const [searchData, setsearchData] = useState([]);
  const [std_name, setStdName] = useState('');
  const [showRes, setShowRes] = useState(false);

  const handleChangeAdmYear = (value: { value: string; label: React.ReactNode }) => { 
    //alert(value) // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    getAdmCounts(value);
  };
  const handleChangeCollectYear = (value: { value: string; label: React.ReactNode }) => {
    getCollection(value);
  };
  const handleChangeTotalYear = (value: { value: string; label: React.ReactNode }) => {
     // { value: "lucy", key: "lucy", label: "Lucy (101)" }
     yrr = value;
    getFetchCourseCounts(yrr,mm);
  };

  const handleChangeMonth = (value: { value: string; label: React.ReactNode }) => {
    mm = value;
    getFetchCourseCounts(yrr,mm);
  };

  const month = [
    
          {
            value: '0',
            label: 'All'
          },
          {
            value:'01',
            label:'January'
          },
          {
             value:'02',
             label:'Febuary'
          },
          {
             value:'03',
             label:'March'
          },
          {
            value:'04',
            label:'April'
          },
          {
             value:'05',
             label:'May'
          },
          {
            value:'06',
            label:'June'
          },
          {
            value:'07',
            label:'July'
          },
          {
             value:'08',
             label:'August'
          },
          {
             value:'09',
             label:'September'
          },
          {
             value:'10',
             label:'October'
          },
          {
             value:'11',
             label:'November'
          },
          {
             value:'12',
             label:'December'
          }
    
  ]



 


  

  const getStdCounts = ()=>{
    getData('getstdcounts/'+centre_code+'/'+adm_type).then((res)=>{
        setstdCntData(res.data);
      //  console.log();
        total_stds = res.data[0].total_stds;
        total_completed = res.data[0].total_completed;
        total_active = res.data[0].total_active;
        total_dropout = res.data[0].total_dropout;
        total_crf = res.data[0].total_crf;
        //console.log(stdCntData[0]['total_stds'])
    })
  }

  const getAdmCounts = (y:any)=>{
    getData('getadmcounts/'+centre_code+'/'+adm_type+'/'+y).then((res)=>{
        //console.log(res.data); 
        setAdmLabels(res.data['month']);
        setAdmCountData(res.data['count']);
       
        //console.log(adm_count_data);
        //console.log(adm_labels);
    })
  }

  const getFetchCourseCounts = (y:any,m:any)=>{
    getData('getcoursecounts/'+centre_code+'/'+adm_type+'/'+y+'/'+m).then((res)=>{
        //console.log(res.data); 
        setCourseLabels(res.data['course']);
        setCourseCountData(res.data['std_count']);
        setTotalAdmCnt(res.data['sum']);
        //console.log(adm_count_data);
        //console.log(adm_labels);
    })
  }

  const getCollection = (y:any)=>{
    getData('getcollection/'+centre_code+'/'+adm_type+'/'+y).then((res)=>{
        setCollectLabels(res.data['month']);
        setCollectAdm(res.data['ad_amt']);
        setCollectInst(res.data['in_amt']);
    })
  }

  const SearchData = () =>{
   // console.log(std_name)
    
    getData('searchbyname/'+std_name+'/'+centre_code+'/'+adm_type).then((res)=>{
       setShowRes(true);
        setsearchData(res.data)
    })
  }

  

  useEffect(()=>{
    let login = JSON.parse(String(localStorage.getItem('login')));
    // console.log(login);
     centre_code = login.centre_code;
     adm_type = login.adm_type;
    // centre_name = login.centre_name;

    for(var i=2010;i<2050;i++){
        year.push(
            {
                value: String(i),
                label: String(i),
            }
        )
    }
    //console.log(year);
    getStdCounts();

    getAdmCounts(new Date().getFullYear());
    getFetchCourseCounts(new Date().getFullYear(),'0');
    getCollection(new Date().getFullYear());
   
    
   },[]);

  return (
    <div className='Documents'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Dashboard</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    
                    <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-blue order-card">
                              <div className="card-block">
                                  <h6 className="m-b-20">Total Students</h6>
                                  <h2 className="text-right"><i className="fa fa-calendar f-left"></i><span>{total_stds}</span></h2>
                                
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-green order-card">
                              <div className="card-block">
                                  <h6 className="m-b-20">Active</h6>
                                  <h2 className="text-right"><i className="fa fa-rocket f-left"></i><span>{total_active}</span></h2>
                                  
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-yellow order-card" >
                              <div className="card-block">
                                  <h6 className="m-b-20">Completed</h6>
                                  <h2 className="text-right"><i className="fa fa-clipboard f-left"></i><span>{total_completed}</span></h2>
                                  
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-pink order-card">
                              <div className="card-block">
                                  <h6 className="m-b-20">Dropout</h6>
                                  <h2 className="text-right"><i className="fa fa-credit-card f-left"></i><span>{total_dropout}</span></h2>
                                  
                              </div>
                          </div>
                      </div>
                    </div>

                    <div className="row p-2">
                      <div className="col-md-4 col-xl-3">
                        <Link to={'crflist'} >
                          <div className="card bg-c-blue order-card" >
                              <div className="card-block">
                                  <h6 className="m-b-20">CRF Request</h6>
                                  <h2 className="text-right"><i className="fa fa-refresh f-left"></i><span>{total_crf}</span></h2>
                                  
                              </div>
                          </div>
                          </Link>
                      </div>

                      <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-green order-card" >
                              <div className="card-block">
                                  <h6 className="m-b-20">Pending</h6>
                                  <h2 className="text-right"><i className="fa fa-refresh f-left"></i>
                                  <span>{total_dropout}</span></h2>
                                 
                              </div>
                          </div>
                      </div>
                    </div>

                    <div className="row p-2">
                        <div className="col-12">
                            <Space.Compact style={{ width: '60%' }} >
                                <Input placeholder='Enter name to search' value={std_name} onChange={e=> setStdName(e.target.value)} size="large" />
                                <Button type="primary" onClick={SearchData} size="large">Search</Button>
                            </Space.Compact>
                        </div>

                        <div className="col-12 mt-1">
                            <Card title="Student List" extra={<Button onClick={()=>setShowRes(false)}>Clear</Button>} className={showRes?'':'hidden'}>
                                <div className="row">
                                    {
                                        searchData.map((item:any)=>{
                                            return(
                                                <div  className="col-6 mt-2 p-3">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img alt="" width="80" height="80" className="img-thumbnail" src={img_url+item.std_img} />
                                                        </div>
                                                        <div  className="col-10 p-2">
                                                        <Link to={"/studentprofile/" + btoa(String(item.std_id)) + "/" + btoa(String(item.std_name)) + "/" + btoa(String(item.std_img))+ "/" + btoa(String(item.centre_name))} state={{id:15,type:'y'}} >
                                                            <a  href="javascript:void(0)"  title="Click to View Profile"><u><h4  style={{color: '#007bff'}}>{item.std_name}</h4></u></a></Link> ({item.std_id})<br />Course: {item.course_name}<br />{item.centre_name}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </Card>
                        </div>

                    </div>

                    <div className="row p-2">
                        <div className="col-8">
                            <Card title="Admission Overview" extra={
                                <Select
                                
                                defaultValue={{ value: String(new Date().getFullYear()), label: String(new Date().getFullYear()) }}
                                style={{ width: 120 }}
                                onChange={handleChangeAdmYear}
                               
                              >
                               {
                                year.map((item:any)=>{
                                    return(
                                        <Select.Option value={item.value}>{item.label}</Select.Option>
                                    )
                                })
                               }
                              </Select>
                            }>
                               <Line 
                                    data={{
                                        labels: adm_labels,
                                        datasets: [
                                            {
                                                label: "Admissions",
                                                data: adm_count_data,
                                            }, 
                                        ],
                                    }}                                    
                                />
                            </Card>
                        </div>
                        <div className="col-4">
                            <Card title="Student Status">
                            <Doughnut 
                                    data={{
                                        labels: ["Active","Completed","Pending","Dropout"],
                                        datasets: [
                                            {
                                                label: "Student Status",
                                                data: [total_active,total_completed,total_crf,total_dropout],
                                            }, 
                                        ],
                                    }}                                    
                                />
                            </Card>
                        </div>
                    </div>
                    <div className="row p-2">
                        <div className="col-12">
                            <Card title="Collection Overview" extra={
                                <Select
                                
                                defaultValue={{ value: String(new Date().getFullYear()), label: String(new Date().getFullYear()) }}
                                style={{ width: 120 }}
                                onChange={handleChangeCollectYear}
                               
                              >
                               {
                                year.map((item:any)=>{
                                    return(
                                        <Select.Option value={item.value}>{item.label}</Select.Option>
                                    )
                                })
                               }
                              </Select>
                            }>
                                <Bar 
                                    data={{
                                        labels: collect_labels,
                                        datasets: [
                                            {
                                                label: "Installments",
                                                data: collect_inst_amt,
                                                
                                            },
                                            {
                                                label: "Admissions",
                                                data: collect_adm_amt,
                                                
                                            }, 
                                        ],
                                    }} 
                                    options={
                                        
                                        {
                                            scales:{
                                                x: {
                                                    stacked: true
                                                },
                                                y: {
                                                    stacked: true
                                                }
                                            },
                                            plugins:{
                                                tooltip:{
                                                    mode:'index',
                                                    callbacks: {
                                                        footer: function(items) {
                                                        return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }                                  
                                />
                            </Card>
                        </div>
                        
                    </div>

                    <div className="row p-2">
                        <div className="col-12">
                            <Card title={"Total Students :" + total_stds +"|| Total Admission:"+total_adm_cnt} extra={
                                <>
                                <Select
                                    className='mr-2'
                                    defaultValue={{ value: String(new Date().getFullYear()), label: String(new Date().getFullYear()) }}
                                    style={{ width: 120 }}
                                    onChange={handleChangeTotalYear}

                                    >
                                        {year.map((item: any) => {
                                            return (
                                                <Select.Option value={item.value}>{item.label}</Select.Option>
                                            );
                                        })}
                                    </Select>
                                <Select
                                   
                                  defaultValue={{ value: String(0), label: 'ALL' }}
                                  style={{ width: 120 }}
                                  onChange={handleChangeMonth}

                              >
                                  {month.map((item: any) => {
                                      return (
                                          <Select.Option value={item.value}>{item.label}</Select.Option>
                                      );
                                  })}
                              </Select></>
                            }>
                                <Bar 
                                    data={{
                                        labels: course_labels,
                                        datasets: [
                                            {
                                                label: "Student Count",
                                                data: course_count_data,
                                            }, 
                                        ],
                                    }}                                    
                                />
                            </Card>
                        </div>
                        
                    </div>
                </div>
             
            </section>
    </div>
  )
}

export default Dashboard