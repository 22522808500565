import React, { CSSProperties, useCallback, useEffect, useState } from 'react'

import {Table, Tag,theme,Card, Button, Modal, Input,Form,Select,message  } from 'antd';

import type { ColumnsType } from 'antd/es/table';
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';




import './style.css';



const { Option } = Select;


type FieldType = {
  menu_name?: string;
  menu_route?: string;
  menu_icon?: string;
  is_sub_menu?: string;
  menu_order?:number;
};

interface DataType {
  menu_id: number;
  menu_name?: string;
  menu_route?: string;
  menu_icon?: string;
  is_sub_menu?: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Menu ID',
    dataIndex: 'menu_id',
    key: 'menu_id',
    
  },
  {
    title: 'Menu Name',
    dataIndex: 'menu_name',
    key: 'menu_name',
  },
  {
    title: 'Menu Route',
    dataIndex: 'menu_route',
    key: 'menu_route',
  },
  {
    title: 'Menu Icon',
    dataIndex: 'menu_icon',
    key: 'menu_icon',
  },
  {
    title: 'Has Sub Menu',
    dataIndex: 'is_sub_menu',
    key: 'is_sub_menu',
  },
  {
    title: 'Menu Order',
    dataIndex: 'menu_order',
    key: 'menu_order',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
        <Button type="primary" danger>Delete</Button>
    ),
    
  },
];

//let data:any;




function Menus() {
    const [svrData, setsvrData] = useState();
    
    const [data, setMenu] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    

    

                          
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log('Success:', values);
   
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  

    const {
        token: { colorBgContainer },
      } = theme.useToken();

      const [isModalOpen, setIsModalOpen] = useState(false);
      const [ishide, setIsHide] = useState(false);
      const showModal = () => {
        setIsModalOpen(true);
      };


      const onFormSubmit = useCallback(async () => {
        form
          .validateFields()
          .then(() => {
            // Validation is successful
            let formData = form.getFieldsValue();
            postData('addmenu',formData).then((res)=>{
            //console.log(res);
            if(res.data){
              //setMenuName("");
              successMsg('Menu Create Successfully');
              setIsModalOpen(false);
              loadData();  
            }
          })

          })
          .catch((errors) => {
            // Errors in the fields

            errorMsg('Error Try Again');
          });
      }, [form]);

      const handleOk = () => {

        
        //console.log(form.getFieldsError());
        

    
         /* let formData = new FormData();
          formData.append("menu_name",menu_name);
          postData('http://127.0.0.1:8000/addmenu/',formData).then((res)=>{
            //console.log(res);
            if(res.data){
              setMenuName("");
              alert("menu Added");
              setIsModalOpen(false);
              loadData();  
            }
          })*/
          

      };

      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const loadData = ()=>{

        getData('getmenu/').then((res)=>{setMenu(res.data)});
      
        //console.log(svrData); 
         
      }

      

      useEffect(()=>{
       loadData();
      },[]);


  return (
    
    <div className="Appmenu font-style">
          

    
      <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Menus</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Menu List" extra={<Button className={ishide? 'hidden' : ''} type='primary' onClick={showModal}>Add Menu</Button>} >
                        <Table className='font-style' columns={columns} dataSource={data} size="small"/>
                    </Card>
                    </div>
                </div>
              </div>
            </section>

      
       
        

        <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Add New Menu" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={onFormSubmit}  >
            Submit
          </Button>,
        ]}>
           
              <Form.Item<FieldType>
                label="Menu Name"
                name="menu_name"
                rules={[{ required: true, message: 'Please enter Menu Name!' }]}
              >
                <Input className='font-style' />
              </Form.Item>

              <Form.Item<FieldType>
                label="Menu Route"
                name="menu_route"
                
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Menu Icon"
                name="menu_icon"
                rules={[{ required: true, message: 'Please enter Menu icon!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item<FieldType> name="is_sub_menu" label="Has Sub Menu" rules={[{ required: true,message: 'Please Select!' }]}>
                <Select
                  placeholder="Select a option"
                 
                  allowClear
                >
                  <Option value="y">Yes</Option>
                  <Option value="n">No</Option>
                  
                </Select>
              </Form.Item>
              <Form.Item<FieldType>
                label="Menu Order"
                name="menu_order"
                rules={[{ required: true, message: 'Please enter Menu order number!' }]}
              >
                <Input />
              </Form.Item>

        </Modal>
        </Form>
    </div>
  )
}



export default Menus