import React, { useCallback, useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import {Table, Tag,theme,Card, Button, Modal, Input,Form,Select  } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';
const { Option } = Select;


type FieldType = {
  
  menu_id?: string;
  sub_menu_name?:string
  link_name?: string;
  link_icon?: string;
  
};

interface DataType {
  sub_menu_id:number;
  sub_menu_name?: string;
  menu_name?: string;
  link_name?: string;
  link_icon?: string;
  
}
  
const columns: ColumnsType<DataType> = [
  {
    title: 'Sub Menu ID',
    dataIndex: 'sub_menu_id',
    key: 'sub_menu_id',
    
  },
  {
    title: 'Sub Menu Name',
    dataIndex: 'sub_menu_name',
    key: 'sub_menu_name',
  },
  {
    title: 'Menu Name',
    dataIndex: 'menu_name',
    key: 'menu_name',
  },
  {
    title: 'Sub Menu Route',
    dataIndex: 'link_name',
    key: 'link_name',
  },
  {
    title: 'Sub Menu Icon',
    dataIndex: 'link_icon',
    key: 'link_icon',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
        <><Button type="primary" >Edit </Button><Button type="primary" danger>Delete</Button></>
    ),
    
  },
];
let data:any;
function Submenus() {
    const {
        token: { colorBgContainer },
      } = theme.useToken();

      const [isModalOpen, setIsModalOpen] = useState(false);
      const [form] = Form.useForm();
      const [svrData, setsvrData] = useState();
      const [menuData, setMenuData] = useState([]);
      const [SubMenuData, setSubMenuData] = useState([]);


      
      const showModal = () => {
        setIsModalOpen(true);
      };

      const handleOk = () => {
        
      };

      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const onFinish = (values: any) => {
        //console.log('Success:', values);
       
      };
      
      const onFinishFailed = (errorInfo: any) => {
       // console.log('Failed:', errorInfo);
      };

      const loadData = ()=>{
        getData('getmenu/').then((res)=>setMenuData(res.data));
      
        //console.log(svrData); 
        
      }

      const loadSubMenuData = ()=>{
        getData('getsubmenu/').then((res)=>setSubMenuData(res.data));
        console.log(SubMenuData)
      }

      const onFormSubmit = useCallback(async () => {
        form
          .validateFields()
          .then(() => {
            // Validation is successful
            let formData = form.getFieldsValue();
            console.log(formData);
            postData('addsubmenu',formData).then((res)=>{
            //console.log(res);
            if(res.data){
             
              successMsg('Sub Menu Created Successfully');
              setIsModalOpen(false);
              loadSubMenuData();
            }
          })

          })
          .catch((errors) => {
            // Errors in the fields
            errorMsg('Error Try Again');
          });
      }, [form]);

      useEffect(()=>{
        loadSubMenuData();
        loadData();
       },[]);

      
  return (
    <div className="AppSubmenu">
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Sub Menus</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card title="Sub Menu List" extra={<Button type='primary' onClick={showModal}>Add Menu</Button>} >
                        <Table columns={columns} dataSource={SubMenuData} size="small"/>
                    </Card>
                    </div>
                </div>
              </div>
            </section>

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Add New Sub Menu" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" onClick={onFormSubmit}  >
            Submit
          </Button>,
        ]}>
           <Form.Item<FieldType> name="menu_id" label="Selet Menu" rules={[{ required: true,message: 'Please Select!' }]}>
                <Select
                  placeholder="Select a option"
                 
                  allowClear
                >
                  {
                    menuData.map((item:any)=>{
                      return(
                        <Option value={item.menu_id}>{item.menu_name}</Option>
                      )
                    })
                  }
                  
                  
                </Select>
              </Form.Item>
              <Form.Item<FieldType>
                label="Sub Menu Name"
                name="sub_menu_name"
                rules={[{ required: true, message: 'Please enter Menu Name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Sub Menu Route"
                name="link_name"
                rules={[{ required: true, message: 'Please enter Menu Route!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Sub Menu Icon"
                name="link_icon"
                rules={[{ required: true, message: 'Please enter Menu icon!' }]}
              >
                <Input />
              </Form.Item>
              

        </Modal>
        </Form>
    </div>
  )
}

export default Submenus