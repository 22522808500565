import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {getData, successMsg} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Input, Modal, Select, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';

const { Option } = Select;
type FieldType = {
    map_element_id?: string;
    map_menu_id?: string;
    map_sub_menu_id?: string;
    
  };
  
  interface DataType {
    map_id : number;
    element_name?: string;
    menu_name?: string;
    sub_menu_name?: string;

  }


  const columns: ColumnsType<DataType> = [
    {
    title: 'Map ID',
    dataIndex: 'map_id',
    key: 'map_id',
    
  },
  {
    title: 'Element Name',
    dataIndex: 'element_name',
    key: 'element_name',
  },
  {
    title: 'Menu Name',
    dataIndex: 'menu_name',
    key: 'menu_name',
  },
  {
    title: 'SubMenu Name',
    dataIndex: 'sub_menu_name',
    key: 'sub_menu_name',
  },
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <Button type="primary" danger>Delete</Button>
      ),
      
    },
  ];

function Elements() {
    const [data, setElementMap] = useState([]);
    const [ElementData, setElement] = useState([]);
    const [ishide, ShowHide] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [MenuData, setMenu] = useState([]);
    const [SubMenuData, setSubMenuData] = useState([]);
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {
      };
      
    

    const success = (msg:any) => {
      messageApi.open({
        type: 'success',
        content: msg,
      });
    };
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log('Success:', values);
   
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        let formData = form.getFieldsValue();
        postData('addelementmap',formData).then((res)=>{
        //console.log(res);
       // console.log(res.data);
        successMsg("Element Created");
        if(res.data == 1){
          //setMenuName("");
         // alert(1);
         
          setIsModalOpen(false);
          loadData();
          //loadData();  
        }
      })

      })
      .catch((errors) => {
        // Errors in the fields

        console.log("no");
      });
  }, [form]);
  const [messageApi, contextHolder] = message.useMessage();
  const loadElementData = ()=>{

    getData('getelement/').then((res)=>setElement(res.data));
  
    //console.log(svrData); 
     
  }

  const loadMenuData = ()=>{

    getData('getmenu/').then((res)=>setMenu(res.data));
  
    //console.log(svrData); 
     
  }
  const loadSubMenuData = ()=>{
    getData('getsubmenu/').then((res)=>setSubMenuData(res.data));
   // console.log(SubMenuData)
  }

  const loadData = ()=>{
    getData('getelementmap/').then((res)=>setElementMap(res.data));
   // console.log(SubMenuData)
  }

  useEffect(()=>{
    loadData();
    loadElementData();
    loadMenuData();
    loadSubMenuData();
   },[]);
  return (
    <div className='Elements'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Elements Mapping</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Element Map List" extra={<Button className={ishide? 'hidden' : ''} type='primary' onClick={showModal}>Map Element</Button>} >
                        <Table className='font-style' columns={columns} dataSource={data} size="small" />
                    </Card>
                    </div>
                </div>
              </div>
            </section>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Add New Element" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={onFormSubmit}  >
            Submit
          </Button>,
        ]}>
           
           <Form.Item<FieldType> name="map_element_id" label="Element" rules={[{ required: true,message: 'Please Select!' }]}>
                <Select
                  placeholder="Select a option"
                 
                  allowClear
                >
                  {
                    ElementData.map((item:any)=>{
                      return(
                        <Option value={item.element_id}>{item.element_name}</Option>
                      )
                    })
                  }
                  
                  
                </Select>
              </Form.Item>
              <Form.Item<FieldType> name="map_menu_id" label="Menu" >
                <Select
                  placeholder="Select a option"
                 
                  allowClear
                >
                  {
                    MenuData.map((item:any)=>{
                      return(
                        <Option value={item.menu_id}>{item.menu_name}</Option>
                      )
                    })
                  }
                  
                  
                </Select>
              </Form.Item>

              <Form.Item<FieldType> name="map_sub_menu_id" label="Sub Menu" >
                <Select
                  placeholder="Select a option"
                 
                  allowClear
                >
                  {
                    SubMenuData.map((item:any)=>{
                      return(
                        <Option value={item.sub_menu_id}>{item.sub_menu_name}</Option>
                      )
                    })
                  }
                  
                  
                </Select>
              </Form.Item>

        </Modal>
        </Form>
    </div>
  )
}

export default Elements