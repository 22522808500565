import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useNavigate } from 'react-router-dom'
import {errorMsg, getData, successMsg} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Spin, Table, Tag, Upload, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
//import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';
import moment from 'moment';


let centre_code:any;
let adm_type:any;
let centre_name:any;

let CentreSelect:any;

type FieldType = {
  std_name?: string;
  std_gender?: string;
  std_dob?: string;
  std_email?: string;
  std_mobile?: string;
  std_wappno?:number;
  std_fname?:string;
  std_fmobile?:string;
  wapp_no?:number;
  std_course?:number;
  doj?:string;
  form_no?:string;
  cert_id?:string;
  std_address?:string;
  std_state?:string;
  std_city?:string;
  std_pin?:string;
  centre?:string;
  
};

let  image:any;
function Registration() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [CourseData, setCourse] = useState([]);
  const [CertData, setCert] = useState([]);
  const [FileData, setFileData] = useState();
  const [centreData, setCentreData] = useState([]);
  const [loading, setLoading] = useState(false);

  
  

  
  const onFinish = (values: any) => {
    console.log('Success:', values);

   // console.log(values['cert_id']);
   
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const loadCourseData = ()=>{

    getData('getcourses/').then((res)=>setCourse(res.data));
  
    //console.log(svrData); 
     
  }

  const loadCertData = ()=>{

    getData('getcertificate/').then((res)=>setCert(res.data));
  
    //console.log(svrData); 
     
  }

  const loadCentre = ()=>{
    getData('getcentres/').then((res)=>{
      setCentreData(res.data)
    });
  }

  

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        let value = form.getFieldsValue();
        
        //console.log(image);

        let formData = new FormData();

        formData.append("std_name",value.std_name);
        formData.append("std_gender",value.std_gender);
        formData.append("std_dob",moment(value.std_dob.toString()).format('YYYY-MM-DD'));
        formData.append("std_mobile",value.std_mobile);
        formData.append("std_email",value.std_email);
        formData.append("std_wappno",value.std_wappno);
        formData.append("std_fname",value.std_fname);
        formData.append("std_fmobile",value.std_fmobile);
        formData.append("wapp_no",value.wapp_no);
        formData.append("std_course",value.std_course);
        formData.append("doj",moment(value.doj.toString()).format('YYYY-MM-DD'));
        formData.append("form_no",value.form_no);
        formData.append("cert_id",value.cert_id);
        formData.append("std_address",value.std_address);
        formData.append("std_state",value.std_state);
        formData.append("std_city",value.std_city);
        formData.append("std_pin",value.std_pin);
        formData.append("std_img",image);
        formData.append("centre_code",value.centre);

        setLoading(true);
        postData('addstudent',formData).then((res)=>{
      //  console.log(res);
         setLoading(false);
          if(res.data){
            //setMenuName("");
            successMsg("Registration Successfull!!");
            navigate('/studentprofile/'+btoa(String(res.data['std_id']))+'/'+btoa(String(res.data['name']))+'/'+btoa(String(res.data['img']))+'/'+btoa(String(res.data['cname'])))
          }
        })

      })
      .catch((errors) => {
        // Errors in the fields

        errorMsg('Error Try Again');
      });
  }, [form]);

  /*const onChange = (checkedValues: CheckboxValueType[]) => {
      //console.log('checked = ', checkedValues);
      //console.log('String', checkedValues.toString());
    };*/

    const handleImage = (e:any) =>{
      //console.log(e.target.files[0])
      //setFileData(e.target.files[0]);
     // console.log(FileData);
      image = e.target.files[0];
    }
  
  
  useEffect(()=>{
    let login = JSON.parse(String(localStorage.getItem('login')));
   // console.log(login);
    centre_code = login.centre_code;
    adm_type = login.adm_type;
    centre_name = login.centre_name;

    
    loadCentre();
    loadCourseData();
    loadCertData();
    form.setFieldValue('std_pin','788001');
    form.setFieldValue('std_city','SILCHAR');
    form.setFieldValue('std_state','ASSAM');
    //form.setFieldValue('std_course',10)
   },[]);

  return (
    <div className='Registration'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">New Registration</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <Spin tip="Loading" size="large" spinning={loading}>
          <section className="content">
          <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              encType="multipart/form-data"
            >
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                      <Card className='font-style' title="Personal Information "  >
                          <div className="row">
                            <div className="col">
                              <Form.Item<FieldType>
                                  label="Student Name"
                                  name="std_name"
                                  rules={[{ required: true, message: 'Please enter Student Name!' }]}
                                >
                                  <Input className='font-style' />
                                </Form.Item>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <Form.Item<FieldType>
                                  label="Gender"
                                  name="std_gender"
                                  rules={[{ required: true, message: 'Please Select Gender!' }]}
                                >
                                  <Select placeholder="Select">
                                      <Select.Option value={"M"}>Male</Select.Option>
                                      <Select.Option value={"F"}>Female</Select.Option>
                                      <Select.Option value={"TG"}>Trans-gender</Select.Option>
                                  </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                              <Form.Item<FieldType>
                                    label="Date of Birth"
                                    name="std_dob"
                                    rules={[{ required: true, message: 'Please Select Date of Birth!' }]}
                                  >
                                    <DatePicker style={{width:"100%"}}  format={'DD-MM-YYYY'}/>
                                  </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <Form.Item<FieldType>
                                    label="Student Email"
                                    name="std_email"
                                    rules={[{ required: true, message: 'Please enter Student Email!' }]}
                                  >
                                    <Input className='font-style' />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                              <Form.Item<FieldType>
                                    label="Student Mobile Number"
                                    name="std_mobile"
                                    rules={[{ required: true, message: 'Please enter Student Number!' }]}
                                  >
                                    <Input className='font-style' />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                            <Form.Item<FieldType>
                                  label="Is Whatapp No?"
                                  name="std_wappno"
                                  rules={[{ required: true, message: 'Please Select!' }]}
                                >
                                  <Select placeholder="Select">
                                      <Select.Option value={1}>Yes</Select.Option>
                                      <Select.Option value={0}>No</Select.Option>
                                  </Select>
                                </Form.Item>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <Form.Item<FieldType>
                                    label="Guardian Name"
                                    name="std_fname"
                                    rules={[{ required: true, message: 'Please enter Guardian Name!' }]}
                                  >
                                    <Input className='font-style' />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                              <Form.Item<FieldType>
                                    label="Guardian Mobile Number"
                                    name="std_fmobile"
                                    rules={[{ required: true, message: 'Please enter Guardian Mobile Number!' }]}
                                  >
                                    <Input className='font-style' />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                            <Form.Item<FieldType>
                                  label="Is Whatapps No?"
                                  name="wapp_no"
                                  rules={[{ required: true, message: 'Please Select!' }]}
                                >
                                 <Select placeholder="Select">
                                      <Select.Option value={1}>Yes</Select.Option>
                                      <Select.Option value={0}>No</Select.Option>
                                  </Select>
                                </Form.Item>
                            </div>
                          </div>
                      </Card>
                    </div>
                </div>

                <div className="row p-2 mt-2">
                    <div className="col-lg-12">
                      <Card className='font-style' title="Course Details"  >
                          <div className="row">
                            <div className="col-6">
                            <Form.Item<FieldType> 
                              label="Course"
                              name="std_course"
                              rules={[{ required: true, message: 'Please Select Course!' }]}>
                              <Select placeholder="Select Course">
                                {
                                  CourseData.map((item:any)=>{
                                    return(
                                      <Select.Option value={item.course_id}>{item.course_name}</Select.Option>
                                    )
                                  })
                                }
                                
                              </Select>
                            </Form.Item>
                            </div>
                            <div className="col-6">
                              <Form.Item<FieldType>
                                    label="Date of Joining"
                                    name="doj"
                                    rules={[{ required: true, message: 'Please Select Date of Joining!' }]}
                                  >
                                    <DatePicker style={{width:"100%"}} format={'DD-MM-YYYY'} />
                                  </Form.Item>
                            </div>
                            
                          </div>
                          <div className="row">
                            <div className="col-6">
                                <Form.Item<FieldType>
                                    label="Enquiry Number"
                                    name="form_no"
                                    rules={[{ required: true, message: 'Please enter Enquiry Number!' }]}
                                  >
                                    <Input  />
                                  </Form.Item>
                            </div>
                            <div className="col-6">
                               <Form.Item<FieldType>
                                    label="Choose Certificates"
                                    name="cert_id"
                                    rules={[{ required: true, message: 'Please Select Certificate!' }]}
                                  >
                                    <Select placeholder="Select Certificates" mode='tags'>
                                {
                                  CertData.map((item:any)=>{
                                    return(
                                      <Select.Option value={item.certi_id}>{item.certi_name}</Select.Option>
                                    )
                                  })
                                }
                                
                              </Select>
                                  </Form.Item>
                            </div>
                          </div>
                      </Card>
                    </div>
                </div>
                <div className="row p-2 mt-2">
                    <div className="col-lg-12">
                      <Card className='font-style' title="Address Details"  >
                          <div className="row">
                            <div className="col-6">
                            <Form.Item<FieldType>
                                    label="Full Address"
                                    name="std_address"
                                    rules={[{ required: true, message: 'Please enter Full Address!' }]}
                                  >
                                  <Input />
                                    
                                  </Form.Item>
                            </div>
                            <div className="col-6">
                            <Form.Item<FieldType>
                                    label="State"
                                    name="std_state"
                                    rules={[{ required: true, message: 'Please enter State!' }]}
                                    
                                  >
                                  <Input value={"Assam"}/>
                                    
                                  </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                            <Form.Item<FieldType>
                                    label="City"
                                    name="std_city"
                                    rules={[{ required: true, message: 'Please enter City!' }]}
                                  >
                                  <Input />
                                    
                                  </Form.Item>
                            </div>
                            <div className="col-6">
                            <Form.Item<FieldType>
                                    label="PIN"
                                    name="std_pin"
                                    rules={[{ required: true, message: 'Please enter PIN!' }]}
                                    
                                  >
                                  <Input />
                                    
                                  </Form.Item>
                            </div>
                          </div>
                      </Card>
                    </div>
                </div>
                <div className="row p-2 mt-2">
                    <div className="col-lg-12">
                      <Card className='font-style' title="Upload Image"  >
                          <div className="row">
                            <div className="col-6">
                            <Form.Item label="Choose Image" name="fileList" rules={[{ required: true, message: 'Please Choose Image!' }]}>
                                <input type='file' onChange={handleImage} className='form-control'/>
                              </Form.Item>
                            </div>
                            <div className="col-6">
                            <Form.Item<FieldType> 
                              label="Select Centre"
                              name="centre"
                              rules={[{ required: true, message: 'Please Select Centre!' }]}>
                              <Select placeholder="Select Centre">
                                  {
                                    adm_type == 1 ? (
                                      centreData.map((item:any)=>{
                                        return(
                                          <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                        )
                                      })
                                    ) : (
                                      <Select.Option value={centre_code}>{centre_name}</Select.Option>
                                    )
                                  }
                                
                              </Select>
                            </Form.Item>
                            </div>
                          </div>
                      </Card>
                    </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Button className='d-block mx-auto mb-2' type='primary' onClick={onFormSubmit}>Register</Button>
                  </div>
                </div>
              </div>
              </Form>
            </section>
            </Spin>
        </div>
    
  )
}

export default Registration