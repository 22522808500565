import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useLocation } from 'react-router-dom';
import {errorMsg, getPagePer,successMsg} from '../services/index';
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Input, Modal, Select, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';

const { Option } = Select;
let adm_type:any;
let user_id:any;
let centre_code:any;
type FieldType = {
  
  centre?:string;
  email?:string;
  staff_code?:string;
  role?:number;
};
  
  interface DataType {
    adm_id : number;
    staff_code?: string;
    adm_usr?: string;
    adm_type?: string;
    adm_status?: string;
    staff_name?: string;
    
  }




function Users() {
    const [data, setUser] = useState();
    const location = useLocation();
    const m_id = location.state.id;
    const type = location.state.type;
    const [showAddBtn, setAddBtn] = useState(false);
    const [showBanBtn, setBanBtn] = useState(false);
    const [showPerBtn, setPerBtn] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [centreData, setCentreData] = useState([]);
    const [staffData, setStaffData] = useState([]);

    const columns: ColumnsType<DataType> = [
      {
        title: 'Admin ID',
        dataIndex: 'adm_id',
        key: 'adm_id',
        
      },
      {
        title: 'Staff Code',
        dataIndex: 'staff_code',
        key: 'staff_code',
      },
      {
          title: 'Admin Username',
          dataIndex: 'staff_name',
          key: 'staff_name',
      },
      {
        title: 'Email',
        dataIndex: 'adm_usr',
        key: 'adm_usr',
      },
      {
          title: 'Type',
          dataIndex: 'adm_type',
          key: 'adm_type',
      },
      
      {
        title: 'Action',
        key: 'action',
        render: (_, {adm_id}) => (
            <><Button type="primary" danger className={showBanBtn?'':'hidden'}><i className="fas fa-ban"></i></Button>
         <Link to={'/permissions/'+adm_id} ><Button type="primary" className={showPerBtn?'':'hidden'}>Permissions</Button></Link>   </>
        ),
        
      },
    ];
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {
      };
      
    

    const success = (msg:any) => {
      messageApi.open({
        type: 'success',
        content: msg,
      });
    };
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log('Success:', values);
   
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const loadCentre = ()=>{
    getData('getcentres/').then((res)=>{
      setCentreData(res.data)
    });
  }
  const handleCentreChange = (value: string) => {
    centre_code = value;
    
    loadstafflist(centre_code);
  }

  const loadstafflist = (centre_code:any)=>{
       
    getData('getstafflist/'+centre_code).then((res)=>{
      console.log(res.data);
      setStaffData(res.data)
      
    });
    
    //console.log(svrData); 
     
  }
  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        let value = form.getFieldsValue();
        let formData = new FormData();
        //formData.append("centre_code",value.centre_code);
        formData.append("staff_code",value.staff_code);
        formData.append("email",value.email);
        formData.append("role",value.role);
        postData('adduser',formData).then((res)=>{
        //console.log(res);
        console.log(res.data)
        if(res.data){
          //setMenuName("");

          if(res.data['data']==true){
            successMsg(res.data['msg']);
            setIsModalOpen(false);
            loadData();  
          }else{
            errorMsg(res.data['msg']);
            setIsModalOpen(false);
            loadData();
          }
          
        }
      })

      })
      .catch((errors) => { 
        // Errors in the fields

        console.log("no");
      });
  }, [form]);
  const [messageApi, contextHolder] = message.useMessage();
  const loadData = ()=>{

    getData('getuser/').then((res)=>setUser(res.data));
  
    //console.log(svrData); 
     
  }

  useEffect(()=>{
    let login = JSON.parse(String(localStorage.getItem('login')));
    adm_type = login.adm_type;
    user_id = login.user_id;
    loadCentre();
    getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res)=>{
      for(let i=0;i<res.data.length;i++){
        //console.log(res.data[i].per_element_id);
        switch(parseInt(res.data[i].per_element_id)){
          case 1:
                break;
          case 2:
                setAddBtn(true);
                break;
          case 10:
                setBanBtn(true);
                break;
          case 17:
                setPerBtn(true);
                break;
        }
      }
    })
    loadData();
   },[]);

  return (
    <div className='Users'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Users</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Users List" extra={<Button className={showAddBtn? '' : 'hidden'} type='primary' onClick={showModal}>Add User</Button>} >
                        <Table className='font-style' columns={columns} dataSource={data} size="small" />
                    </Card>
                    </div>
                </div>
              </div>
            </section>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Add New Element" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={onFormSubmit}  >
            Submit
          </Button>,
        ]}>
           <Form.Item<FieldType> 
                label="Select Centre"
                name="centre"
                rules={[{ required: true, message: 'Please Select Centre!' }]}>
                <Select placeholder="Select Centre" onChange={handleCentreChange}>
                    {
                      
                        centreData.map((item:any)=>{
                          return(
                            <Select.Option value={item.centre_code} >{item.centre_name}</Select.Option>
                          )
                        })
                      
                    }
                  
                </Select>
              </Form.Item>

              <Form.Item<FieldType> 
                label="Select Staff"
                name="staff_code"
                rules={[{ required: true, message: 'Please Select Staff!' }]}>
                <Select placeholder="Select Staff" >
                    {
                      
                        staffData.map((item:any)=>{
                          return(
                            <Select.Option value={item.staff_code} >{item.staff_name}( {item.staff_code} )</Select.Option>
                          )
                        })
                      
                    }
                  
                </Select>
              </Form.Item>
              
              <Form.Item<FieldType>
                label="Email ID"
                name="email"
                rules={[{ required: true, message: 'Please enter email id!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Role"
                name="role"
                rules={[{ required: true, message: 'Please select role !' }]}
              >
               <Select placeholder="Please select Payment Mode" >
                  <Option value="1">Super Admin</Option>
                  <Option value="2">AVKSL Admin</Option>
                  <Option value="3">Centre Admin</Option>
                  <Option value="4">Counsellor</Option>
                  <Option value="5">Faculty</Option>
                  <Option value="6">Office Assistant</Option>
                </Select>
              </Form.Item>

        </Modal>
        </Form>
    </div>
  )
}

export default Users