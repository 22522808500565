import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useParams,useLocation } from 'react-router-dom';
import {getPagePer} from '../services/index';
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space, Divider  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import dayjs from 'dayjs';
import { img_url } from '../services/index';

import './style.css';
import { aD } from '@fullcalendar/core/internal-common';
import { forEachChild } from 'typescript';
import layout from 'antd/es/layout';


let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let std_id:any;
let user_id:any;

type FieldType = {
    centre?: string;
    stdlist?:string;
  }

  const { Option } = Select;
let options: any=[];
let std_data: any[];
let editStdList: any[];

let ex_id_a:any;
interface DataType {
    sno:number;
    ex_id:number
    module_name : string;
    ex_date?: string;
    marks?: string;
    std_cnt?:number,
    marks_cnt?:number,
    marks_status?:string,
   
  }


  interface ExDataView {
    sno?:number,
    std_id?:string;
    std_name?:string;
    ex_id?:number;
    course_name?:string;
    th_mark?:number;
    pr_mark?:number;
  }

  interface ExDataEdit {
    sno?:number,
    std_id?:string;
    std_name?:string;
    ex_id?:number;
    course_name?:string;
   
  }


  
  

function Exams() {


  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Module Name',
      dataIndex: 'module_name',
      key: 'module_name',
    },
    {
        title: 'Exam Date',
        dataIndex: 'ex_date',
        key: 'ex_date',
    },
    {
        title: 'Marks Status',
        dataIndex: 'marks_status',
        key: 'marks_status',
        render: (_,{std_cnt,marks_cnt,marks_status})=>(
          <>
              Student count: {std_cnt} || Marks entry: {marks_cnt} || {marks_status}
              
          </>

           
        )
    },
    {
      title: 'Marks Entry',
      key: 'action',
      render: (_, {ex_id})=>{
        return <>
          
            <Button type="primary" className={showMarksBtn?'':'hidden'} size='small' onClick={()=>showDrawerMarksEntry(ex_id)} >Enter Marks</Button>
          
        </>;
      }
    },
      
    {
      title: 'Actions',
      key: 'action',
      render: (_, {ex_id}) => (
          <> <Tooltip title="View">
            
              <Button type="primary" size='small' className={showViewBtn?'':'hidden'} onClick={()=> showDrawerView(ex_id)}><i className="fas fa-eye"></i></Button>
           
            </Tooltip>
  
            <Tooltip title="Edit Exam">
              <Button  type="primary" className={showEditBtn?'':'hidden'} size='small' onClick={()=>showDrawerEdit(ex_id)} danger><i className="fas fa-edit"></i></Button>
            </Tooltip>
          
            
          </>
      ),
      
    },
  ];

  const exdataCols  = [
    {
        title: '#',
        dataIndex: 'sno',
        key: 'sno',
    },
    {
      title: 'Student ID',
      dataIndex: 'std_id',
      key: 'std_id',
    },
    {
      title: 'Student Name',
      dataIndex: 'std_name',
      key: 'std_name',
    },
    {
      title: 'Course Name',
      dataIndex: 'course_name',
      key: 'std_id',
    },
    {
      title: 'Theory Mark',
      dataIndex: 'th_mark',
      key: 'th_mark',
      render: (_: any,{th_mark}:any) => (
       th_mark == null ? <>{th_mark}</> : th_mark >= pmarks ? <>{th_mark} | PASS </> : <>{th_mark} | FAIL </> 
      )
    },
    {
      title: 'Practical Mark',
      dataIndex: 'pr_mark',
      key: 'pr_mark',
      render: (_: any,{pr_mark}:any) => (
        pr_mark == null ? <>{pr_mark}</> : pr_mark >= pmarks ? <>{pr_mark} | PASS </> : <>{pr_mark} | FAIL </>
      )
    },
    {
      title: "Result",
      key: 'ex_id',
      dataIndex: 'ex_id',
      render:({ex_id}:any) => (
        <Tooltip title="View Result">
          <Button type='primary' danger size='small'><i className="fas fa-eye" ></i></Button> 
        </Tooltip>
      )
    }

  ];

  const exEditCols  = [
    {
        title: '#',
        dataIndex: 'sno',
        key: 'sno',
    },
    {
      title: 'Student ID',
      dataIndex: 'std_id',
      key: 'std_id',
    },
    {
      title: 'Student Name',
      dataIndex: 'std_name',
      key: 'std_name',
    },
    {
      title: 'Course Name',
      dataIndex: 'course_name',
      key: 'std_id',
    },
    {
      title: "Action",
      key: 'action',
     
      render:({ex_id}:any,{std_id}:any) => (
        <Tooltip title="Remove">
          <Button type='primary' danger size='small' onClick={()=>removeStd(ex_id,std_id)}><i className="fas fa-trash" ></i></Button> 
        </Tooltip>
      )
    }

  ];


 /* const exMarksCols  = [
    {
        title: '#',
        dataIndex: 'sno',
        key: 'sno',
    },
    {
      title: 'Student ID',
      key: 'std_id',
      render: ({std_id}:any,{sno}:any) => (
        <>
         <Input placeholder="Basic usage" id={'std_id'+sno} value={std_id} size="small" readOnly className={'std_id'+sno} bordered={false} />
        </>
       )
    },
    {
      title: 'Student Name',
      dataIndex: 'std_name',
      key: 'std_name',
    },
    {
      title: 'Course Name',
      dataIndex: 'course_name',
      key: 'std_id',
    },
    {
      title: 'Theory Mark',
     
      key: 'th_mark',
      render: ({th_mark}:any,{sno}:any,) => (
       <>
        <Input placeholder="Basic usage" onChange={e =>handleChangeMarks(e.target.value,sno)} value={th_mark} size="small" id={'pr_mark'+sno} className={'pr_mark'+sno}/>
       </>  

       
      )
    },
    {
      title: 'Practical Mark',
     
      key: 'pr_mark',
      render: ({pr_mark}:any,{sno}:any) => (
        <>
        <Input placeholder="Basic usage" id={'pr_mark'+sno} value={pr_mark} size="small" className={'pr_mark'+sno}/>
       </>
      )
    },
    

  ];*/
    
  


    const [centreData, setCentreData] = useState([]);
    const [stdData, setStdData] = useState([]);
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setElement] = useState();
    const [module_id, setModule] = useState([]);
    const [mod_name, setMod]=useState("");
    const [fmarks, setFmarks]=useState("");
    const [pmarks, setPmarks]=useState("");
    const [stdDataView, setstdDataView] = useState([]);
    const [stdDataEdit, setstdDataEdit] = useState([]);
    const [stdMarksData, setstdMarks] = useState<any>([]);

    const location = useLocation();
    const m_id = location.state.id;
    const type = location.state.type;
    const [showExamBtn, setExamBtn] = useState(false);
    const [showMarksBtn, setMarksBtn] = useState(false);
    const [showViewBtn, setViewBtn] = useState(false);
    const [showEditBtn, setEditBtn] = useState(false);
   

    const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
          
        });
      }

      const loadStdList = ()=>{
        // console.log(centre_code);
        getData('getStdlist/'+String(centre_code)).then((res)=>{
            options = res.data;
            setStdData(res.data);
            
           // console.log(stdData);
        })
       }

       const handleCentreChange = (value: string) => {
            centre_code = value;
            loadStdList();
            loadExam();
        }

        const loadExam = ()=>{
            getData('getexamdata/'+String(centre_code)+'/'+String(std_id)).then((res)=>{
                
                setElement(res.data);
                setLoading(false);
               // console.log(stdData);
            })
        }

        const handleChange = (value: string) => {
            std_id = value;
            setLoading(true);
            loadExam();
        }

        const [open, setOpen] = useState(false);
        const [openEdit, setEditOpen] = useState(false);
        const [openView, setViewOpen] = useState(false);
        const [openMarksEntry, setMarksEntryOpen] = useState(false);

        const showDrawer = ()=> {
          setOpen(true);
          LoadModule();
          
        };

        const showDrawerEdit = (id:any) =>{
          //alert(id);
          getExamStdEdit(id);
          setEditOpen(true);
        };

        const showDrawerView = (id:any) =>{
         // alert("Edit" + id);

          getExamStdData(id);
          setViewOpen(true);
        };

        const showDrawerMarksEntry = (id:any) =>{
          // alert("Edit" + id);
 
           getExamMarksStdData(id);
           setMarksEntryOpen(true);
         };

        const getExamStdData = (id:any) =>{
          setLoading(true);
          getData('getexamstddata/'+id).then((res:any)=>{
            setstdDataView(res.data['data']);
            setMod(res.data['module']);
            setFmarks(res.data['fmarks']);
            setPmarks(res.data['pmarks']);
            setLoading(false);
          })
        }

        const getExamStdEdit = (id:any) =>{
          setLoading(true);
          getData('getexameditdata/'+id).then((res:any)=>{
            setstdDataEdit(res.data['data']);
            setMod(res.data['module']);
            editStdList = res.data['std_data'];
            ex_id_a = id;
            setLoading(false);
          })
        }

        const removeStd = (eid:any,sid:any) =>{
          //alert('Ex_id: '+ eid + ' Std_id: '+sid);
          setLoading(true);
          getData('removestdexam/'+eid+'/'+sid).then((res:any)=>{
            getExamStdEdit(eid);
          })
        }

        const getExamMarksStdData = (eid:any) => {
          setLoading(true);
          getData('getmarksentrydata/'+eid).then((res:any)=>{
            setstdMarks(res.data['data']);
            setMod(res.data['module']);
            setFmarks(res.data['fmarks']);
            setPmarks(res.data['pmarks']);
            setLoading(false);
           // console.table(stdMarksData);
          })
        }
    
        const onClose = () => {
          setOpen(false);
          setEditOpen(false);
          setViewOpen(false);
          setMarksEntryOpen(false);
        };

        

        const onFormSubmit = useCallback(async () => {
          form
            .validateFields()
            .then(() => {
              // Validation is successful
              setLoading(true);
              let value = form.getFieldsValue(); 
              let formData = new FormData();

              formData.append("ex_date",moment(value.ex_date.toString()).format('YYYY-MM-DD'));
              formData.append("ex_dur",value.ex_dur);
              formData.append("module_id",value.module_id);
              formData.append("std_id",value.std_id);
              formData.append("cid",centre_code);

              
             postData('schduleexam',formData).then((res)=>{
             // console.log(res.data['success']);
              setLoading(false);
              
              if(res.data == 1){
                successMsg('Exam Schedule Successfully');
                loadExam();
              }else{
                errorMsg('Error, Try Again');
              }
  
              
            })
  
            })
            .catch((errors) => {
              // Errors in the fields
              errorMsg("Error, Try Again!!")
              //errorMsg('Error Try Again');
            });
        }, [form]);

        const onAddFormSubmit = useCallback(async () => {
          addForm
            .validateFields()
            .then(() => { 
              setLoading(true);
              let value = addForm.getFieldsValue(); 
              let formData = new FormData();
             // console.log(value.std_id_a);
              formData.append('std_id',value.std_id_a);
              formData.append('ex_id_a',ex_id_a);

              postData('addExamStd', formData).then((res:any)=>{
                getExamStdEdit(ex_id_a);
              })
            })
        },[addForm]);

        const onMarksSubmit = ()=>{

          //console.table(stdMarksData);
          setLoading(true);
          postData('savemarks',stdMarksData).then((res:any)=>{
            setLoading(false);
            if(res.data == 1){
              successMsg('Marks Updated!!');
              loadExam();
              setMarksEntryOpen(false);
            }else{
              setLoading(false);
              errorMsg('Error Try Again');
            }
          })
          
        }

        

        const LoadModule = ()=>{
          getData('getmodules').then((res)=>{
            setModule(res.data);
            
          })
        }

        const  handleModuleChange = (value:any)=>{
          
          setLoading(true);
          let d = form.getFieldValue('ex_date');
          let date = moment(d.toString()).format('YYYY-MM-DD');
          //alert(date);
          getData('checkexam/'+value+'/'+date+'/'+centre_code).then((res)=>{
            setLoading(false);
            if(res.data > 0){
              errorMsg("Exam Already Schedule for this Module");
              form.setFieldValue('module_id',"");
            }else{
              std_data = res.data;
            }
          })
        }

       useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id;
        std_id = 0;
       // console.log(centre_code);
        loadCentre();
        form.setFieldValue('centre',centre_code);
        form.setFieldValue('date',dayjs(Date().toString()));
        getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res)=>{
          for(let i=0;i<res.data.length;i++){
            switch(parseInt(res.data[i].per_element_id)){
              case 1:
                    break;
              case 4:
                    setExamBtn(true);
                    break;
              case 5:
                    setMarksBtn(true);
                    break;
              case 3:
                    setEditBtn(true);
                    break;
              case 9:
                    setViewBtn(true);
                    break;
            }
          }
        })
        loadStdList();
        loadExam();
        setLoading(true);

        
        //loadAttend();
       },[]);
  return (
    <div className='Exams'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Exam Schedule</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
              <div className="row mb-2">
               
                <div className=" col-sm-12 d-flex">
                <Form form={form} layout='inline'>
                   <Form.Item<FieldType> 
                       
                       name="centre"
                       className="d-flex"
                       >
                     <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                         {
                         adm_type == 1 ? (
                             centreData.map((item:any)=>{
                                 return(
                                           <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                       
                                     )       
                                 })
                             ) : (
                                 <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                             )
                         }
                     
                     </Select>
                     </Form.Item>
                     <Form.Item<FieldType> 
                       
                       name="stdlist"
                       
                       >
                     <Select placeholder="Select Name"    className='flex-fill' 
                        
                        showSearch
                        style={{ width: 400 }}
                        onChange={handleChange}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                        }
                        options={options}

                     >
                     
                     </Select>
                     </Form.Item>
                     
                     </Form>
                </div>{/* /.col */}
               
                
              </div>{/* /.row */}
              
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
            
                    <Card className='font-style' title="Exam List"  extra={<Button  type='primary' onClick={showDrawer} className={showExamBtn?'':'hidden'} >Schedule Exam</Button>}>
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={data} size="small" />
                        </Spin>
                    </Card>
                    </div>
                </div>
              </div>
            </section>
        <Drawer
          title="Schedule Exam"
          width={520}
          onClose={onClose}
          open={open}
          zIndex={1000}
          extra={
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onFormSubmit} type="primary">
                Submit
              </Button>
            </Space>
          
            }
            style={{
              marginTop:50,
            }}
            
          >
      <Spin tip="Loading" size="large" spinning={loading}>
        
        <Form layout="vertical"  form={form} autoComplete="off">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="ex_date"
                label="Exam Date"
                rules={[{ required: true, message: 'Please enter Date' }]}
              >
                <DatePicker style={{width:"100%"}}  format={'DD-MM-YYYY'}/>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="ex_dur"
                label="ex_dur"
                rules={[{ required: true, message: 'Please enter Duration' }]}
              >
                <Input placeholder="Please enter duration"  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="module_id"
                label="Choose Module"
                rules={[{ required: true, message: 'Please select Module' }]}
              >
                <Select placeholder="Please select Module" onChange={handleModuleChange} >
                  {
                    module_id.map((item:any)=>{
                      return(
                        <Select.Option value={item.module_id}>{item.module_name}</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="std_id"
                label="Select Students"
                rules={[{ required: true, message: 'Please choose Students' }]}
              >
                <Select placeholder="Please Select Students" mode="tags" showSearch
                    
                   
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                    }
                    options={std_data}>
                
                 
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
        </Form>
        
        </Spin>
      </Drawer>
      

      <Drawer
          title="Edit Exam Schedule"
          width={700}
          onClose={onClose}
          open={openEdit}
          zIndex={1000}
          
            style={{
              marginTop:50,
            }}
            
          >

            <Spin tip="Loading" size="large" spinning={loading}>
            
              <div className="row">
                <div className="col">
                 <b>Module : {mod_name} </b> 
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <Form form={addForm}>
                  
                    <Row gutter={16}>
                      <Col span={18}>
                        <Form.Item
                          name="std_id_a"
                          label="Select Students"
                          rules={[{ required: true, message: 'Please choose Students' }]}
                        >
                          <Select placeholder="Please Select Students" mode="tags" showSearch
                              
                            
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                              filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                              }
                              options={editStdList}>
                          
                          
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                            <Button type='primary' onClick={onAddFormSubmit} >Add Students</Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            <Divider />

              <Table className='font-style' columns={exEditCols} dataSource={stdDataEdit} size="small" />
                        

            </Spin>

          </Drawer>


          <Drawer
            title="View Exam Schedule Details"
            width={900}
            onClose={onClose}
            open={openView}
            zIndex={1000}
          
            style={{
              marginTop:50,
            }}
            
          >
           <Spin tip="Loading" size="large" spinning={loading}>
            
              <div className="row">
                <div className="col">
                 <b> Module : {mod_name} | Full Marks: {fmarks} | Pass Marks: {pmarks} </b>
                </div>
              </div>

              <Table className='font-style' columns={exdataCols} dataSource={stdDataView} size="small" />
                        

            </Spin>

          </Drawer>


          <Drawer
            title="Exam Marks Entry"
            width={1000}
            onClose={onClose}
            open={openMarksEntry}
            zIndex={1000}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onMarksSubmit} type="primary">
                  Submit
                </Button>
              </Space>
            
              }
            style={{
              marginTop:50,
            }}
            
          >
           <Spin tip="Loading" size="large" spinning={loading}>
            
              <div className="row">
                <div className="col">
                 <b> Module : {mod_name} | Full Marks: {fmarks} | Pass Marks: {pmarks} </b>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="ant-table ant-table-small">
                      <div className="ant-table-container">
                        <div className="ant-table-content">
                          <table className='table table-sm' >
                           <colgroup></colgroup>
                           <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell" scope="col">#</th>
                                  <th className="ant-table-cell" scope="col">Student ID</th>
                                  <th className="ant-table-cell" scope="col">Student Name</th>
                                  <th className="ant-table-cell" scope="col">Course Name</th>
                                  
                                  <th className="ant-table-cell" scope="col">Theory Mark</th>
                                  <th className="ant-table-cell" scope="col">Practical Mark</th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                {
                                  stdMarksData.map((item:any,index: any)=>{
                                    return(
                                      <tr className="ant-table-row ant-table-row-level-0" key={item.sno}>
                                        <td className="ant-table-cell">{item.sno}</td>
                                        <td className="ant-table-cell">{item.std_id}</td>
                                        <td className="ant-table-cell">{item.std_name}</td>
                                        <td className="ant-table-cell">{item.course_name}</td>
                                        
                                        <td className="ant-table-cell">
                                          <input type='text' className='form-control-sm' name="theory_mark"  value={stdMarksData[index]['theory_mark']} 
                                         
                                          onInput={(e)=>{
                                            setstdMarks((a:any)=>{
                                              const res = [...a];
                                              res[index]['theory_mark'] = (e.target as HTMLInputElement).value;
                                              return res;
                                            })
                                          }}
                                          
                                          />
                                        </td>
                                        <td className="ant-table-cell">
                                          <input type='text' className='form-control-sm' name="practical_mark" value={stdMarksData[index]['practical_mark']}
                                            onInput={(e)=>{
                                              setstdMarks((a:any)=>{
                                                const res = [...a];
                                                res[index]['practical_mark'] = (e.target as HTMLInputElement).value;
                                                return res;
                                              })
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                                  
                              </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
                  
                    
                    
                </div>
              </div>
                        

            </Spin>

          </Drawer>


    </div>
  )
}

export default Exams




