import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space, Divider  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import dayjs from 'dayjs';
import { img_url } from '../services/index';

import './style.css';
import { aD } from '@fullcalendar/core/internal-common';
import { forEachChild } from 'typescript';
import layout from 'antd/es/layout';


let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let std_id:any;

type FieldType = {
    centre?: string;
    stdlist?:string;
  }

  const { Option } = Select;
let options: any=[];
let std_data: any[];

function Marks() {
    const [centreData, setCentreData] = useState([]);
    const [stdData, setStdData] = useState([]);
    const [form] = Form.useForm();
    const [std_name, setName]= useState("");
    const [sid, setId]= useState("");
    const [std_course, setCourse]= useState("");
    const [std_img, setImg]= useState("");
    
    const [loading, setLoading] = useState(false);
    const [ishide, setIshide] = useState(true);
    const [Comp, setComp] = useState<any>([]);
    const [Incomp, setIncomp] = useState<any>([]);
    const {id} = useParams();

    const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
          
        });
      }

      const loadStdList = ()=>{
        // console.log(centre_code);
        getData('getStdlist/'+String(centre_code)).then((res)=>{
            options = res.data;
            setStdData(res.data);
            
           // console.log(stdData);
        })
       }

       const handleCentreChange = (value: string) => {
        centre_code = value;
        loadStdList();
    }

    

    const handleChange = (value: string) => {
       // alert(value);
        std_id = value;
        loadData();
        setLoading(true);
       
    }

    const loadData = () =>{
        
        getData('getstdmarksdata/'+String(centre_code)+'/'+String(std_id)).then((res)=>{
            setIshide(false);
            //setElement(res.data);
            //console.log(res.data['std_data'].std_id);
            setName(res.data['std_data'].std_name);
            setId(res.data['std_data'].std_id);
            setCourse(res.data['std_data'].course_name);
            setImg(res.data['std_data'].std_img);
            setComp(res.data['comp']);
            setIncomp(res.data['notcomp']);
            setLoading(false);
           // console.log(stdData);
        })
    }

    const SubmitMarks = (i:any) =>{
        if(i==1){
           // console.log(Comp);
           postData('savemarks',Comp).then((res)=>{

                successMsg('Marks Updated!!');
                loadData();
                
            })
        }else{
           // console.log(Incomp);
            postData('savemarks',Incomp).then((res)=>{
                if(res.data == 1){
                    successMsg('Marks Updated!!');
                    loadData();
                   
                  }else{
                    setLoading(false);
                    errorMsg('Error Try Again');
                  }
            })
        }
    }

    useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        std_id = 0;
       // console.log(centre_code);
        loadCentre();
        form.setFieldValue('centre',centre_code);
        form.setFieldValue('date',dayjs(Date().toString()));
        loadStdList();
        if(id != '0'){
            //alert(atob(String(id)));
            handleChange(atob(String(id)));
           // setChide(true);
          }
        
       
       // setLoading(true);

        
        //loadAttend();
       },[]);
  return (
    <div className='Marks'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Marks Entry/View</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
              <div className="row mb-2">
               
                <div className=" col-sm-12 d-flex">
                <Form form={form} layout='inline'>
                   <Form.Item<FieldType> 
                       
                       name="centre"
                       className="d-flex"
                       >
                     <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                         {
                         adm_type == 1 ? (
                             centreData.map((item:any)=>{
                                 return(
                                           <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                       
                                     )       
                                 })
                             ) : (
                                 <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                             )
                         }
                     
                     </Select>
                     </Form.Item>
                     <Form.Item<FieldType> 
                       
                       name="stdlist"
                       
                       >
                     <Select placeholder="Select Name"    className='flex-fill' 
                        
                        showSearch
                        style={{ width: 400 }}
                        onChange={handleChange}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                        }
                        options={options}

                     >
                     
                     </Select>
                     </Form.Item>
                     
                     </Form>
                </div>{/* /.col */}
               
                
              </div>{/* /.row */}
              
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Spin tip="Loading" size="large" spinning={loading}>
                    <div className={ishide == true ? 'hidden': ''}>
                        <Card className='font-style'  >
                            <div className="row">
                                <div className="col-6">
                                    <h3>Student ID: {sid}</h3>
                                    <h3>Name: {std_name}</h3>
                                    <h3>Course: {std_course}</h3>
                                </div>
                                <div className="col-6">
                                    <img width="180" height="200" className="img-thumbnail d-block mx-auto" src={img_url+std_img} alt="" />
                                </div>
                            </div>
                        
                        </Card>
                        <Card className='font-style mt-2' title="Completed Modules"  >
                            <div className="row">
                                <div className="col-12">
                                    <table className='table table-stripted'>
                                        <thead>
                                            <tr>
                                                <th>Module</th>
                                                <th>Theory Marks</th>
                                                <th>Practical Marks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Comp.map((item:any,index:any)=>{
                                                    return(
                                                        <tr>
                                                            <td>{item.module_name}</td>
                                                            <td>
                                                                <input type='text' value={Comp[index]['theory_mark']} name='theory_mark' 
                                                                onInput={(e)=>{
                                                                    setComp((a:any)=>{
                                                                        const res = [...a];
                                                                        res[index]['theory_mark'] = (e.target as HTMLInputElement).value;
                                                                        return res;
                                                                    })
                                                                  }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type='text' value={Comp[index]['practical_mark']} name='practical_mark'
                                                                onInput={(e)=>{
                                                                    setComp((a:any)=>{
                                                                        const res = [...a];
                                                                        res[index]['practical_mark'] = (e.target as HTMLInputElement).value;
                                                                        return res;
                                                                    })
                                                                  }}
                                                                />
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })
                                            }
                                            
                                            
                                        </tbody>
                                    </table>
                                    <Button type='primary' className='d-block mx-auto' onClick={()=>SubmitMarks(1)}>Update Marks</Button>
                                </div>
                                
                            </div>
                        
                        </Card>

                        <Card className='font-style mt-2' title="In-Completed Modules"  >
                            <div className="row">
                                <div className="col-12">
                                <table className='table table-stripted'>
                                        <thead>
                                            <tr>
                                                <th>Module</th>
                                                <th>Theory Marks</th>
                                                <th>Practical Marks</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                                Incomp.map((item:any,index:any)=>{
                                                    return(
                                                        <tr>
                                                            <td>{item.module_name}</td>
                                                            <td>
                                                                <input type='text' value={Incomp[index]['theory_mark']} name='theory_mark' 
                                                                onInput={(e)=>{
                                                                    setIncomp((a:any)=>{
                                                                        const res = [...a];
                                                                        res[index]['theory_mark'] = (e.target as HTMLInputElement).value;
                                                                        return res;
                                                                    })
                                                                  }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type='text' value={Incomp[index]['practical_mark']} name='practical_mark'
                                                                onInput={(e)=>{
                                                                    setIncomp((a:any)=>{
                                                                        const res = [...a];
                                                                        res[index]['practical_mark'] = (e.target as HTMLInputElement).value;
                                                                        return res;
                                                                    })
                                                                  }}
                                                                />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            
                                        </tbody>
                                    </table>
                                    <Button type='primary' className='d-block mx-auto' onClick={()=>SubmitMarks(2)}>Submit Marks</Button>
                                </div>
                                
                            </div>
                        
                        </Card>
                    </div>
                    </Spin>
                    </div>
                </div>
              </div>
            </section>
    </div>
  )
}

export default Marks