import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useLocation } from 'react-router-dom';
import {getPagePer} from '../services/index';
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { img_url } from '../services/index'; 
import { back_url } from '../services/index';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let user_id:any;
let std_id:any;
let sid:any;


type FieldType = {
    centre?: string;
    stdlist?:string;
    status?:string;
  }
const { Option } = Select;
let options: any=[];

interface DataType {
    sid : number;
    std_id?: string;
    std_name?: string;
    std_mobile?: string;
    std_img?: string;
    std_status?: number;
    course_name?: string;
    centre_name?:string;
  }



function Studentdetails() {
    const [data, setElement] = useState();
    const [ishide, ShowHide] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [stdData, setStdData] = useState([]);
    const [centreData, setCentreData] = useState([]);
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const location = useLocation();
    const m_id = location.state.id;
    const type = location.state.type;

    const [showViewProfile,setViewProfile] = useState(false);
    const [showEditBtn,setEditBtn] = useState(false);

  


    const columns: ColumnsType<DataType> = [
      {
        title: 'Image',
        dataIndex: 'std_img',
        key: 'std_img',
        render: (std_img) => <img width="50" height="35" className="img-thumbnail" src={img_url+std_img} alt="" />
        
      },
      {
        title: 'Student ID',
        dataIndex: 'std_id',
        key: 'std_id',
      },
      {
          title: 'Student Name',
          dataIndex: 'std_name',
          key: 'std_name',
      },
      {
          title: 'Course Name',
          dataIndex: 'course_name',
          key: 'course_name',
      },
      {
        title: 'Mobile Number',
        dataIndex: 'std_mobile',
        key: 'std_mobile',
      },
      
      {
          title: 'Status',
          dataIndex: 'std_status',
          key: 'std_status',
          fixed: 'right',
          width:100,
          render: (std_status) => std_status == 1 ? <Tag color="processing">Active</Tag> : std_status == 2? <Tag color="warning">Certificate Pending</Tag> : std_status == 3? <Tag color="error">Dropout</Tag> : std_status == 4? <Tag color="default">CFR Pending</Tag> : std_status == 0? <Tag color="success">Completed</Tag> : ""
  
      },
      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width:150,
        render: (_, {std_id,std_name,std_img,centre_name}) => (
            <> <Tooltip title="View Profile">
              <Link to={"/studentprofile/" + btoa(String(std_id)) + "/" + btoa(String(std_name)) + "/" + btoa(String(std_img))+ "/" + btoa(String(centre_name))} state={{id:15,type:'y'}} > 
              <a href="#" target='_blank'  className={showViewProfile?'':'hidden'}>
                <Button type="primary" size='small'><i className="fas fa-eye"></i></Button>
              </a>
              </Link>
              </Tooltip>
              <Tooltip title="Change Status">
                  <Button type="primary" className='ml-1' size='small' onClick={()=>showModal(std_id)}><i className="fas fa-redo"></i></Button>
              </Tooltip>
  
              <Tooltip title="Edit Details"> 
                
                <a href={"https://avksladmin.aranyakvalley.com/#/editstudentdetails/" +  btoa(String(std_id))} target='_blank' className={showEditBtn?'':'hidden'}>
                  <Button type="primary" className='ml-1' size='small' ><i className="fas fa-edit"></i></Button>
                </a>
            
              </Tooltip>
            
              
            </>
        ),
        
      },
    ];

    const showModal = (value:any) => {
        sid = value;
        getData('getstatus/'+value).then((res)=>{
          form1.setFieldValue('status',res.data['std_status']);
        })
        setIsModalOpen(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {
      };

      const loadData = ()=>{
        setLoading(true);
       
        getData('getstudents/'+centre_code+'/'+std_id).then((res)=>{
          setElement(res.data)
          setLoading(false);
        });
        
        //console.log(svrData); 
         
      }

      const loadStdList = ()=>{
        // console.log(centre_code);
        getData('getStdlist/'+String(centre_code)).then((res)=>{
            options = res.data;
            setStdData(res.data);
            
           // console.log(stdData);
        })
       }

      const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
            
        });   
      }

      const handleCentreChange = (value: string) => {
        centre_code = value;
        std_id = 0;
        loadStdList();
        loadData();
      }

      const handleChange = (value: string) => {
        std_id = value;
        loadData();
      }

      const ChangeStatus = () => {
        let value = form1.getFieldsValue();
        getData('changestdstatus/'+sid+'/'+value.status).then((res)=>{
          loadData();
        })
      }
    
      useEffect(()=>{

        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id;
        std_id = 0;
       // console.log(centre_code);
       getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res)=>{
        for(let i=0;i<res.data.length;i++){
          switch(parseInt(res.data[i].per_element_id)){
            case 1:
                  break;
            case 3:
                  setEditBtn(true);
                  break;
            case 9:
                  setViewProfile(true);
                  break;
          }
        }
      })
        loadCentre();
        form.setFieldValue('centre',centre_code);
        loadData();
        loadStdList()
        setLoading(true);
       },[]);
  return (
    <div className='Studentdetails'>
       
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Student List</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
              <div className="row mb-2">
               
                <div className=" col-sm-12 d-flex">
                <Form form={form} layout='inline'>
                   <Form.Item<FieldType> 
                       
                       name="centre"
                       className="d-flex"
                       >
                     <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                         {
                         adm_type == 1 ? (
                             centreData.map((item:any)=>{
                                 return(
                                           <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                       
                                     )       
                                 })
                             ) : (
                                 <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                             )
                         }
                     
                     </Select>
                     </Form.Item>

                     <Form.Item<FieldType> 
                       
                       name="stdlist"
                       
                       >
                     <Select placeholder="Select Name"    className='flex-fill' 
                        
                        showSearch
                        style={{ width: 400 }}
                        onChange={handleChange}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                        }
                        options={options}

                     >
                     
                     </Select>
                     </Form.Item>
                     </Form>
                </div>{/* /.col */}
               
                
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Students"  >
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={data} scroll={{ x: 1200 }} size="small" />
                        </Spin>
                    </Card>
                    </div>
                </div>
              </div>
            </section>


            <Form
              form={form1}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
             
              autoComplete="off"
              
            >

        <Modal title="Change Student Status" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          
        ]}>
           
              
              <Form.Item<FieldType> name="status" label="Change Status" rules={[{ required: true,message: 'Please Select!' }]}>
                <Select
                  placeholder="Select a option"
                 
                  allowClear
                  onChange={ChangeStatus}
                >
                  <Select.Option value={1}>Active</Select.Option> 
                  <Select.Option value={2}>Certificate pending</Select.Option> 
                  <Select.Option value={3}>Dropout</Select.Option> 
                  <Select.Option value={4}>CRF Request</Select.Option>  
                  <Select.Option value={0}>Completed</Select.Option> 
        
                  
                </Select>
              </Form.Item>
              

        </Modal>
        </Form>
            
    </div>
  )
}

export default Studentdetails