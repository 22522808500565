import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useLocation } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Input, Modal, Spin, Table, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {getPagePer, errorMsg, successMsg} from '../services/index';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let std_id:any;
let user_id:any;

type FieldType = {
      module_name?: string;
      module_fmarks?: number;
      module_pmarks?: number;
      days?: number;
      mod_name?:string
    
  };
  
  interface DataType {
    module_id : number;
    module_name?: string;
    module_fmarks?: string;
    module_pmarks?: string;
    mod_name?: string;
    mod_status?: string;
  }


  

function Modules() {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Module ID',
      dataIndex: 'module_id',
      key: 'module_id',
      
    },
    {
      title: 'Module Name',
      dataIndex: 'module_name',
      key: 'module_name',
    },
    {
        title: 'Full Marks',
        dataIndex: 'module_fmarks',
        key: 'module_fmarks',
    },
    {
      title: 'Pass Marks',
      dataIndex: 'module_pmarks',
      key: 'module_pmarks',
    },
    {
        title: 'Mod DESC',
        dataIndex: 'mod_name',
        key: 'mod_name',
      },
      {
        title: 'Status',
        dataIndex: 'mod_status',
        key: 'mod_status',
        render: (mod_status) => mod_status == 1 ? <Tag color="success">Active</Tag> : <Tag color="error">In-Active</Tag>  
      },
    {
      title: 'Action',
      key: 'action',
      render: (_, {module_id}) => (
          <><Button type="primary" danger size='small'><i className="fas fa-ban"></i></Button>
          <Button type="primary"  size='small'><i className="fas fa-edit"></i></Button>
          </>
      ),
      
    },
  ];
    const [data, setElement] = useState();
    const [ishide, ShowHide] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const m_id = location.state.id;
    const type = location.state.type;
    const [showAddBtn, setAddBtn] = useState(false);
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {
      };

      const loadData = ()=>{

        getData('getmodules/').then((res)=>{setElement(res.data)});
        setLoading(false);
        //console.log(svrData); 
         
      }
    
      useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id;
        getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res)=>{
          for(let i=0;i<res.data.length;i++){
            switch(parseInt(res.data[i].per_element_id)){
              case 2:
                    setAddBtn(true);
                    break;
            }
          }
        })
        loadData();

       },[]);
  function onFinish(values: any): void {
    throw new Error('Function not implemented.');
  }

  function onFinishFailed(errorInfo: any): void {
    throw new Error('Function not implemented.');
  }

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        let value = form.getFieldsValue();
        let formData = new FormData();
        //formData.append("centre_code",value.centre_code);
        formData.append("module_name",value.module_name);
        formData.append("module_fmarks",value.module_fmarks);
        formData.append("module_pmarks",value.module_pmarks);
        formData.append("days",value.days);
        formData.append("mod_name",value.mod_name);
        
      
        postData('addmodule',formData).then((res)=>{
        //console.log(res);
        console.log(res.data)
        if(res.data){
          //setMenuName("");

          if(res.data == 1){
            successMsg("Module Created");
            setIsModalOpen(false);
            loadData();  
          }else{
            errorMsg("Error Try Again");
            setIsModalOpen(false);
            loadData();
          }
          
        }
      })

      })
      .catch((errors) => { 
        // Errors in the fields

        console.log("no");
      });
  }, [form]);

  return (
    <div className='Modules'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Modules</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Module List" extra={<Button className={showAddBtn? '' : 'hidden'} type='primary' onClick={showModal}>Add New Module</Button>} >
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={data} size="small" />
                    </Spin>
                    </Card>
                    </div>
                </div>
              </div>
            </section>

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Add New Module" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={onFormSubmit}  > 
            Submit
          </Button>,
        ]}>
           <Form.Item<FieldType> 
                label="Module Name"
                name="module_name"
                rules={[{ required: true, message: 'Please enter Module Name!' }]}>
                <Input />
              </Form.Item>

              <Form.Item<FieldType> 
                label="Module Full Marks"
                name="module_fmarks"
                rules={[{ required: true, message: 'Please enter Module Full Marks!' }]}>
                <Input />
              </Form.Item>
              
              <Form.Item<FieldType>
                label="Module Pass Marks"
                name="module_pmarks"
                rules={[{ required: true, message: 'Please enter Module Pass Marks !' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Module Days"
                name="days"
                rules={[{ required: true, message: 'Please enter Module Days !' }]}
              >
               <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Module tag Name"
                name="mod_name"
                rules={[{ required: true, message: 'Please enter Module tag Name!' }]}
              >
               <Input />
              </Form.Item>

              

        </Modal>
        </Form>
    </div>
  )
}

export default Modules