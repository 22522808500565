import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import {AuthUser, errorMsg, successMsg} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Input, Modal, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ToastContainer } from 'react-toastify';

let login:any;
let staff_name:any="";
let centre_code = "";
let user_id = "";
function Login() {
  const [email,setEmail] = useState("");
  const [passwd, setPasswd] = useState("");
  const navigate = useNavigate();
  const LoginSubmit = (e:any)=>{
    e.preventDefault();
    let formData = new FormData();

    formData.append('email',email);
    formData.append('passwd',passwd);

    AuthUser('checklogin',formData).then((res)=>{
      //console.log(res.data);
      if(res.data == 0){
        errorMsg("Invalid Login Details");
      }else{
        localStorage.setItem('login',JSON.stringify(res.data));
        successMsg("Login Success");
        navigate('/');
      }
    })
  }

  useEffect(()=>{
    
   },[]);
  return (
    <div className='Login'>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          />
            <section className="ftco-section">
        <div className="container">
          
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="wrap">
                <div className="img" style={{backgroundImage: 'url(https://aranyakvalley.com/assets/logos/banner2.jpg)'}} />
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Sign In</h3>
                    </div>
                    <div className="w-100">
                      
                    </div>
                  </div>
                  <form className="signin-form">
                    <div className="form-group mt-3">
                      <input type="text" className="form-control" required onChange={e=>setEmail(e.target.value)}/>
                      <label className="form-control-placeholder" htmlFor="username">Username</label>
                    </div>
                    <div className="form-group">
                      <input id="password-field" type="password" className="form-control" required onChange={e=>setPasswd(e.target.value)} />
                      <label className="form-control-placeholder" htmlFor="password">Password</label>
                      <span  className="fa fa-fw fa-eye field-icon toggle-password" />
                    </div>
                    <div className="form-group">
                      <button  onClick={LoginSubmit} className="form-control btn btn-primary rounded submit px-3">Sign In</button>
                    </div>
                    
                  </form>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Login