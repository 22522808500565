import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { stf_url } from '../services/index';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;



type FieldType = {
    centre?: string;
    stdlist?:string;
  }
const { Option } = Select;
let options: any=[];

interface DataType {
    sid : number;
    staff_code?: string;
    staff_name?: string;
    staff_num?: string;
    staff_desg?: string;
    image?: string;
    staff_status?: number;
    
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <img width="50" height="35" className="img-thumbnail" src={stf_url+image} alt="" />
      
    },
    {
      title: 'Staff ID',
      dataIndex: 'staff_code',
      key: 'staff_code',
    },
    {
        title: 'Staff Name',
        dataIndex: 'staff_name',
        key: 'staff_name',
    },
    {
        title: 'Mobile Number',
        dataIndex: 'staff_num',
        key: 'staff_num',
    },
    {
      title: 'Desgination',
      dataIndex: 'staff_desg',
      key: 'staff_desg',
    },
    
    {
        title: 'Status',
        dataIndex: 'staff_status',
        key: 'staff_status',
        fixed: 'right',
        width:100,
        render: (std_status) => std_status == 1 ? <Tag color="processing">Active</Tag> :  <Tag color="error">In Active</Tag>

    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width:100,
      render: (_, {sid}) => (
          <> <Tooltip title="View Profile">
            <a href={"/studentprofile/" + btoa(String(sid)) + "/" + btoa(String(sid)) + "/" + btoa(String(sid))+ "/" + btoa(String(centre_name))} target='_blank'>
              <Button type="primary" size='small'><i className="fas fa-eye"></i></Button>
            </a>
            </Tooltip>

            <Tooltip title="Edit Details">
              <Button type="primary" className='ml-1' size='small'><i className="fas fa-edit"></i></Button>
            </Tooltip>
          
            
          </>
      ),
      
    },
  ];

function Staffdetails() {
    const [data, setElement] = useState();
    const [ishide, ShowHide] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
   
    const [centreData, setCentreData] = useState([]);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {
      };

      const loadData = ()=>{
       
        getData('getstafflist/'+centre_code).then((res)=>{
          setElement(res.data)
          setLoading(false);
        });
        
        //console.log(svrData); 
         
      }

      const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
          
        });
      }

      const handleCentreChange = (value: string) => {
        centre_code = value;
        loadData();
      }

      
    
      useEffect(()=>{

        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
       // console.log(centre_code);
        loadCentre();
        form.setFieldValue('centre',centre_code);
        loadData();
       // setLoading(true);
       },[]);
  return (
    <div className='Staffdetails'>
       
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Staff List</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
              <div className="row mb-2">
               
                <div className=" col-sm-12 d-flex">
                <Form form={form} layout='inline'>
                   <Form.Item<FieldType> 
                       
                       name="centre"
                       className="d-flex"
                       >
                     <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                         {
                         adm_type == 1 ? (
                             centreData.map((item:any)=>{
                                 return(
                                           <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                       
                                     )       
                                 })
                             ) : (
                                 <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                             )
                         }
                     
                     </Select>
                     </Form.Item>

                     
                     </Form>
                </div>{/* /.col */}
               
                
              </div>{/* /.row */}
              
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Students"  >
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={data} scroll={{ x: 1200 }} size="small" />
                        </Spin>
                    </Card>
                    </div>
                </div>
              </div>
            </section>
            
    </div>
  )
}

export default Staffdetails