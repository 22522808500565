import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';


let login:any;
let staff_name:any="";
let centre_code = "";
let user_id = "";
function Sidebar() {
  const [svrData, setsvrData] = useState();
  const [menuData, setMenuData] = useState([]);
  const loadData = ()=>{
    getData('getsidemenu/'+user_id).then((res)=>{
      setMenuData(res.data)
    
    });
      
    //console.log(svrData); 
    //console.log(menuData);

    
  }

  useEffect(()=>{
    login = JSON.parse(String(localStorage.getItem('login')));

    if(login != null){
      staff_name = login.staff_name;
      centre_code = login.centre_code;
      user_id = login.user_id;
      loadData();
    }
    
   },[]);

  return (
    <div className='Sidebar'>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
  {/* Brand Logo */}
  <a href="index3.html" className="brand-link">
    <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
    <span className="brand-text font-weight-light">AVKSL ADMIN</span>
  </a>
  {/* Sidebar */}
  <div className="sidebar">
    {/* Sidebar user panel (optional) */}
    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
        <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
      </div>
      <div className="info">
        <a href="#" className="d-block">{staff_name}</a>
      </div>
    </div>
    
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        
          {
            menuData?.map((item:any)=>{
              if(item.type == 'n'){
                return(
                  <li key={item.key} className="nav-item menu-open">
                    <Link to={item.path} state={{id:item.key,type:item.type}}>
                    <a href="#" key={item.key} className="nav-link">
                      <i className={item.icon} />
                      <p> 
                        {item.label}
                        
                      </p>
                    </a>
                    </Link>
                    
                    
                  </li>
                );
              }else{
                return(
                  <li key={item.key} className="nav-item">
                    <a href="#" key={item.key} className="nav-link">
                      <i className={item.icon} />
                      <p> 
                        {item.label}
                        <i className="fas fa-angle-left right" />
                        
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {
                        item.children.map((citem:any)=>{
                          return(
                            <li key={citem.sub_key} className="nav-item">
                                <Link to={citem.sub_path} state={{id:citem.sub_key,type:item.type}}>
                                  <a href="#" key={item.key} className="nav-link">
                                  <i className={citem.sub_icon}/>
                                  <p>{citem.sub_label}</p>
                                </a>

                                </Link>
                              
                            </li>
                          );
                        })
                      }
                      
                      
                    </ul>
                </li>
                );
              }
            })
          }

        
     
      </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}
</aside>
    <div className="content-wrapper">
            <Outlet />
    </div>

</div>
  )
}

export default Sidebar