import React from 'react'

function Footer() {
  return (
    <div className='Footer'>
        <footer className="main-footer">
    <strong>Copyright &copy; 2023 <a href="https://aranyakvalley.com/">Aranyak Valley</a>.</strong>
     All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.2.0
    </div>
  </footer>
    </div>
  )
}

export default Footer