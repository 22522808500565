import React from 'react'
import { back_url,img_url } from '../services';
import { useEffect, useState,useRef } from 'react';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space  } from 'antd';
import Clock from 'react-clock';
import QrReader from 'react-qr-reader';

import {CheckLogin, errorMsg, successMsg} from '../services/index';
import {getData} from '../services/index';
import beep1 from  "./beep1.mp3";
import beep2 from  "./beep2.mp3";
import { ToastContainer } from 'react-toastify';

import 'react-clock/dist/Clock.css';  
import axios from 'axios';



//import { Cameras, Scanner } from "react-instascan";
//const { Cameras, Scanner } = require("react-instascan");

let centre_code:any;
let lastScanId :any;

function Takeattendance() {

  const [qrData,setQrData] = useState(null);
  
  const qrRef = React.createRef();
  const [scanDelay, setDelay] = useState(1000);
  
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(new Date());
  const [atndData, setAtndData] = useState([]);

  const [data, setData] = useState('No result');

  const QrScan = (result: any) => {
    if(result == null){
      console.log("no data");
    }else{
      //qrRef.current.stop();
      
      if(lastScanId === result){
        return false;
      }else{
        lastScanId = result;
        saveAttnd(result);
      }
      
      
    }
  }

  const test = (res:any)=>{
    console.log(res);
  }

  const saveAttnd = (res:any) =>{
    setLoading(true);

      getData('saveattendence/'+res).then((res)=>{
        console.log(res.data['data']);
        
        if(res.data['data'] == 1){
         
          let audio = new Audio(beep1);
          audio.play();
          successMsg("Attendance Submitted");
          sendMSG(res.data['entry_time'],res.data['exit_time'],res.data['date'],res.data['name'],res.data['mobile']);
         // loadData();
        }else if(res.data == 0){
        
          let audio = new Audio(beep2.mp3);
          audio.load();
          audio.play();
          errorMsg("Error: Invalid Qr-Code");
        //  loadData();
        }else if(res.data == 3){
         
          let audio = new Audio(beep2);
          audio.load();
          audio.play();
           errorMsg("Error: Invalid Qr-Code");
          // loadData();
         }else if(res.data == 4){
          let audio = new Audio(beep2);
          audio.load();
          audio.play();
           errorMsg("Error : You can exit only after 5 minutes");
          // loadData();
         }else if(res.data['data'] == 2){
          let audio = new Audio(beep1);
          audio.load();
          audio.play();
          successMsg("Exit Attendence Submitted");
          sendMSG(res.data['entry_time'],res.data['exit_time'],res.data['date'],res.data['name'],res.data['mobile']);
         // loadData();
        }
        loadData();
        setLoading(false);


      })
  }

  const scanError = (err: any) => {
    console.log(err);
  }

  const loadData = () =>{
 //   setLoading(true);
    getData('getatnddata/'+centre_code).then((res)=>{
      //console.log(res.data);
      setAtndData(res.data);
      //console.log(atndData);
   //   setLoading(false);
    })
  }

  const sendMSG = async (entry_time: any, exit_time: any, date:any, name:any,mobile:any,) => {
      if(exit_time == 0){
        var message = encodeURI("ATTENDANCE  \nAranyak Institute \nName:" + name + " \nEntry Time:" + entry_time + " \nDate: " + date + " \n ") ;
      }else{
        var message =  encodeURI("ATTENDANCE  \nAranyak Institute \nName:" + name + " \nEntry Time:" + entry_time + " \nExit Time:" + exit_time + "\nDate: " + date + " \n " );
      }

     await axios.get("https://web.cloudwhatsapp.com/wapp/api/send?apikey=a3f421f32a564cc897a22df74374ee7e&mobile="+ mobile +"&msg=" + message).then((res)=>{
      console.log(res);
       return true;
     })
  }


  useEffect(() => {
    let login = JSON.parse(String(localStorage.getItem('login')));
    // console.log(login);
     centre_code = login.centre_code;
     //adm_type = login.adm_type;
     loadData();

    const interval = setInterval(() => setValue(new Date()), 1000);
   

    return () => {
      clearInterval(interval);

    };

    

    
  }, []);

  

  return (
    <div className='Takeattendance'>
      <Spin tip="Loading" size="large" spinning={loading}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="img">
                <img src={back_url+'public/images/avlogo.png'} alt="" width={100} height={100} />
              </div>
              
            </div>
            <div className="col-md-6">
                <h3>AVKSL Attendance Portal</h3>
                <h4>Total Students: {atndData.length} | {new Date().toDateString()}</h4>
              </div>
              <div className="col-md-3">
              <div className='p-1'>
                <Clock value={value} />
              </div>
              </div>
          </div>

          <div className="row">
           <div className="col-5">
                <QrReader
                  delay={scanDelay}
                  onError={scanError}
                  onScan={QrScan}
                  legacyMode={false}
                  facingMode={"user"}
                  style={{width:"500px",height:"500px"}}
                />

                 {/*} <QrReader  
                  onResult={(result, error) => {
                    if (!!result) {
                      test(result);
                    }
          
                    if (!!error) {
                      console.info(error);
                    }
                  }}
                  
                  constraints={{
                    facingMode:"user",
                     
                  }} /> */}
               
                
            </div> 
            <div className="col-7">
              <div className="table" id='table' >
                <table className='table table-striped'>
                  <thead>
                    <th>#</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Course</th>
                    <th>Entry Time</th>
                  </thead>
                  <tbody>
                    {
                      atndData.map((item:any)=>{
                        return(
                          <tr>
                            <td>
                              <img className={'rounded-cricle'} src={img_url + item.std_img} width={40} height={40} />
                            </td>
                            <td>{item.std_id}</td>
                            <td>{item.std_name}</td>
                            <td>{item.course_name}</td>
                            <td>{item.entry_time}</td>
                            
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </Spin>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          />
    </div>
  )
}

export default Takeattendance