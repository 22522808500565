import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import {getData} from '../../services/index';
import { Button, Card, Input, Spin } from 'antd';
import './style.css';
import Item from 'antd/es/list/Item';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
//import EventCalendar from 'react-event-calendar';
//const EventCalendar = require('react-event-calendar');
 




function Attendance() {
  const handleEventClick = (clickInfo:any) => {
    console.log(clickInfo);
  }
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const [events, setEvents] = useState([])
  const loadStdAttendData = ()=>{
    setLoading(true);
    getData('getstudentattendance/'+ atob(String(id))).then((res)=>{
       // console.log(res.data[0]['course_name'])

     setEvents(res.data);
     setLoading(false);
      
    })
  }

  useEffect(()=>{
       
    loadStdAttendData(); 
   },[]);
  return (
    <div className='Attendance'>
      
           <div className="row">
              <div className="col">
                  <div className="box">
                  <FullCalendar
                      plugins={[dayGridPlugin]}
                      initialView='dayGridMonth'
                      weekends={true}
                      events={events}
                      contentHeight={450}
                      aspectRatio={1.5}
                      displayEventTime={false}
                      

                      
                  />
                  </div>
              </div>
           </div>
          
    </div>
  )
}

export default Attendance