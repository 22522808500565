import React, { CSSProperties, useCallback, useEffect, useState } from 'react'

import {Table, Tag,theme,Card, Button, Modal, Input,Form,Select,message  } from 'antd';

import type { ColumnsType } from 'antd/es/table';
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';
import { back_url } from '../services/index';


interface DataType {
    menu_id: number;
    menu_name?: string;
    menu_route?: string;
    menu_icon?: string;
    is_sub_menu?: string;
  }
  
  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'doc_id',
      key: 'doc_id',
      
    },
    {
      title: 'Student ID',
      dataIndex: 'std_id',
      key: 'std_id',
    },
    {
      title: 'Student Name',
      dataIndex: 'std_name',
      key: 'std_name',
    },
    {
      title: 'Doc Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => type == 2 ? <Tag color="processing">ID Card</Tag> : type == 1? <Tag color="success">HSLC/HS Marksheet</Tag>  : ""
    },
    {
      title: 'Document',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <a href={back_url+"public/uploads/doc/"+image} target='_blank'>View Document</a>

    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <Button type="primary" danger>Delete</Button>
      ),
      
    },
  ];

function Documents() {
    const [docData, setDocData] = useState([]);
    const loadData = ()=>{

        getData('getstdocs/').then((res)=>setDocData(res.data));
      
        //console.log(svrData); 
         
      }

      

      useEffect(()=>{
       loadData();
      },[]);
  return (
    <div className='Documents'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Documents</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Document List" >
                        <Table className='font-style' columns={columns} dataSource={docData} size="small" />
                    </Card>
                    </div>
                </div>
              </div>
            </section>
    </div>
  )
}

export default Documents