import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import {getData} from '../../services/index';
import { Button, Card, Modal, Select, Spin } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';

interface DataType {
  upgrade_id : number;
  up_std_id?: string;
  pre_course_id?: string;
  pre_course_amt?: string;
  new_course_id?: string;
  upgrade_date:string;

}

const columns: ColumnsType<DataType> = [
  {
  title: 'Sl No',
  dataIndex: 'sno',
  key: 'sno',
  
},
{
  title: 'Previous Course',
  dataIndex: 'prv_course',
  key: 'prv_course',
},
{
  title: 'Previous Course Amount',
  dataIndex: 'pre_course_amt',
  key: 'pre_course_amt',
},
{
  title: 'New Course Name',
  dataIndex: 'new_course',
  key: 'new_course',
},
  
  {
    title: 'Upgrade Date',
    dataIndex: 'upgrade_date',
  key: 'upgrade_date',
    
    
  },
];

function Coursedetails() {
    const [Sdata, setData] = useState([]);
    const [data, setCourseUpData] = useState();
    const [curData, setCurData] = useState([]);
    const {id} = useParams();
    const [selectedCourse, setSelectedValue] = useState("");
    const [course_name, setCourseName] = useState("");
    const [course_fname, setCourseFname] = useState("");
    const [enq_num, setEnqnum] = useState("");
    const [adm_num, setAdmnum] = useState("");
    const [doj, setDOJ] = useState("");
    const [mod_name, setModName] = useState([]);

    const [CourseData, setCourseData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {
      };


    const loadCourseUpgrade = () =>{
      getData('getstdcourseupgrade/'+ atob(String(id))).then((res)=>{
        //console.log(res.data['up_data']);
        setCourseUpData(res.data['up_data']);
        setCurData(res.data['cur_data'])
      })
    }

    const UpdateCourse = () =>{
      getData('upgradenewcourse/'+atob(String(id))+'/'+selectedCourse).then((res)=>{
        window.location.reload();
      })
    }

    const loadCourseData = ()=>{

      getData('getcourses/').then((res)=>setCourseData(res.data));
    
      //console.log(svrData); 
       
    }

    const onSelectCourseChange = (e: any) => {
      setSelectedValue(e);

      //alert(selectedCourse)
    }



    const loadStdCourseData = ()=>{
        getData('getstudentcoursedetails/'+ atob(String(id))).then((res)=>{
           // console.log(res.data[0]['course_name'])
          setCourseName(res.data[0]['course_name'])
          setCourseFname(res.data[0]['course_fname'])
          setEnqnum(res.data[0]['form_no'])
          setAdmnum(res.data[0]['adm_no']) 
          setDOJ(res.data[0]['doj'])
          setModName(res.data[0]['module_names'])
          
        })


    }

    useEffect(()=>{
       
        loadStdCourseData(); 
        loadCourseUpgrade();
        loadCourseData();
       },[]);
  return (
    <div className='Coursedetails'>
        <div className="row">
               
               <div className="col">
                 <Card className='font-style' title="Course Info"  > 
                    <table  className="table table-striped ng-star-inserted">
                       <tbody>
                         <tr>
                           <td>Course Code</td>
                           <td>{course_name}</td>
                         </tr>
                         <tr>
                           <td>Course Name</td>
                           <td>{course_fname}</td>
                         </tr>
                         <tr>
                           <td>Enquiry Number</td>
                           <td>{enq_num}</td>
                         </tr>
                         <tr>
                           <td>Admission Number</td>
                           <td>{adm_num}</td>
                         </tr>
                         <tr>
                           <td>Date of Joining</td>
                           <td>{doj}</td>
                         </tr>
                       </tbody>
                    </table>
                 </Card>
               </div>
               <div className="col">
                 <Card className='font-style' title="Course Modules"  > 
                    <ul>
                        {
                            mod_name.map((item:any)=>{
                                return(
                                    <li>{item.mod_name}</li>
                                )
                            })
                        }
                    </ul>
                 </Card>
               </div>
           </div>

           <div className="row mt-2">
              <div className="col">
              <Card className='font-style' title="Course Upgrade Details" extra={<Button type='primary' onClick={showModal}>Upgrade Course</Button>}  > 
                  <Table className='font-style' columns={columns} dataSource={data} size="small" />
                </Card>
              </div>
           </div>

           <Modal title="Upgrade Course" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={UpdateCourse}  >
            Submit
          </Button>,
        ]}>
           
              <div className="row">
                <div className="col-12">
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Current Course</th>
                        <th>Current Course Fee</th>
                        <th>Total Amount Paid</th>
                        <th>Total Amount Due</th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                          curData.map((item: any)=>{
                            return(
                              <tr>
                                <td>{item.course_name}</td>
                                <td>{item.std_fee}</td>
                                <td>{item.total_amt}</td>
                                <td>{item.std_fee-item.total_amt}</td>
                              </tr>
                            )
                          })
                        }
                    </tbody>
                  </table>
                </div>
                
              </div>
              <div className="row">
              <div className="col-12 mt-1">
                  <label>Select Course:</label>
                  <Select placeholder="Select Course" style={{width:'100%'}} value={selectedCourse} onChange={onSelectCourseChange}>
                                  {
                                    CourseData.map((item:any)=>{
                                      return(
                                        <Select.Option value={item.course_id}>{item.course_name}</Select.Option>
                                      )
                                    })
                                  }
                                  
                                </Select>
                </div>
              </div>
              

        </Modal>
    </div>
  )
}

export default Coursedetails