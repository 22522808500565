import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useParams,useLocation } from 'react-router-dom'
import {getPagePer} from '../services/index';
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import dayjs from 'dayjs';
import { img_url } from '../services/index';
import { back_url } from '../services/index';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import './style.css';
import { aD } from '@fullcalendar/core/internal-common';
import axios from 'axios';

type FieldType = {
    date_range?: string;
    centre?:string
    
  }

  let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let user_id:any;
let std_id:any;
let sid:any;

  const { RangePicker } = DatePicker;
  interface DataType {
    center_code: number;
    std_id?: string;
    receipt_no?: string;
    pay_type?: string;
    fee_type?: string;
    name?:string;
    receipt_date?:string;
    amount?:string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Sl No',
      dataIndex: 'sno',
      key: 'sno',
      
    },
    {
      title: 'Student Id',
      dataIndex: 'std_id',
      key: 'std_id',
    },
    {
      title: 'Student name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Reciept No',
      dataIndex: 'receipt_no',
      key: 'receipt_no',
    },
    {
      title: 'Payment Mode',
      dataIndex: 'pay_type',
      key: 'pay_type',
    },
    {
      title: 'Payment For',
      dataIndex: 'fees',
      key: 'fees',
    },
    {
      title: 'Payment Date',
      dataIndex: 'receipt_date',
      key: 'receipt_date',
    },
    {
      title: 'Amount',
      dataIndex: 'receipt_date',
      key: 'receipt_date',
      
    },
  ];

  //let data: readonly any[] | undefined = [];

function Paymentreports() {
    const [data, setData] = useState([]);
     const [form] = Form.useForm();
     const [centreData, setCentreData] = useState([]);
     const [loading, setLoading] = useState(false);
    const [ishide, setIshide] = useState(true);
     const onFormSubmit = useCallback(async () => {
        form
          .validateFields()
          .then(() => {
            // Validation is successful
            let value = form.getFieldsValue();
           // console.log(value.date_range[0].$d);
            //console.log(moment(value.date_range[0].$d.toString()).format('YYYY-MM-DD'));
            let date1 = moment(value.date_range[0].$d.toString()).format('YYYY-MM-DD');
            let date2 = moment(value.date_range[1].$d.toString()).format('YYYY-MM-DD');
            let centre_code = value.centre;
            setLoading(true);

            //console.log("date1: "+ date1 + "  date2: "+ date2);
    
            getData('getinstallmentslist/'+centre_code+'/'+date1+'/'+date2).then((res)=>{
                setLoading(false)
                setIshide(false);
                setData(res.data['data']);
            })
    
          })
          .catch((errors) => {
            // Errors in the fields
    
            errorMsg('Error Try Again');
          });
      }, [form]);

      const handleCentreChange = (value: string) => {
        centre_code = value;
        
      }

      const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
            
        });   
      }

      const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        

        var name = "GST_REPORT_" + new Date().toJSON().slice(0,10)

        saveAs(blob, name + ".xlsx");
    };

      useEffect(()=>{

        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id;
        std_id = 0;
       // console.log(centre_code);
       
        loadCentre();
        form.setFieldValue('centre',centre_code);
       // loadData();
       
       },[]);
  return (
    <div className='Paymentreports'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Payment Reports</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
              <div className="row mb-2">
               
                <div className=" col-sm-12 d-flex">
                    <Form form={form} layout='inline'>
                    <Form.Item<FieldType> 
                       
                       name="centre"
                       className="d-flex"
                       >
                     <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                         {
                         adm_type == 1 ? (
                             centreData.map((item:any)=>{
                                 return(
                                           <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                       
                                     )       
                                 })
                             ) : (
                                 <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                             )
                         }
                     
                     </Select>
                     </Form.Item>
                    <Form.Item<FieldType> 
                       
                       name="date_range"
                       className="d-flex"
                       >
                            <RangePicker format={'DD-MM-YYYY'} />
                        </Form.Item>
                        <Button className='d-block mx-auto mb-2' type='primary' onClick={onFormSubmit}>Search</Button>
                    </Form>
                </div>{/* /.col */}
               
                
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content"  >
              <div className="container-fluid">
              <div className="row p-2">
                    <div className="col-lg-12">
                    <Spin tip="Loading" size="large" spinning={loading}>
                      <div className={ishide == true ? 'hidden': ''}>
                      <Card className='font-style' title="Payment List" extra={<><Button type="primary" onClick={()=>exportToExcel()} ><i className="fas fa-file-excel"></i>Download</Button></>} >
                          <Table className='font-style' columns={columns} dataSource={data} size="small"/>
                      </Card>
                      </div>
                    </Spin>
                    </div>
                </div>
              </div>
          </section>  
    </div>
  )
}

export default Paymentreports