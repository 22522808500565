import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { img_url } from '../services/index';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import dayjs from 'dayjs';


let centre_code:any;
let adm_type:any;
let centre_name:any;
let Cvalue:any;

interface DataType {
    sid : number;
    std_id?: string;
    std_name?: string;
    std_mobile?: string;
    std_img?: string;
    std_status?: number;
    course_name?: string;
    centre_name?:string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'sno',
      key: 'sno'
    },
    {
      title: 'Image',
      dataIndex: 'std_img',
      key: 'std_img',
      render: (std_img) => <img width="50" height="35" className="img-thumbnail" src={img_url+std_img} alt="" />
      
    },
    {
      title: 'Student ID',
      dataIndex: 'std_id',
      key: 'std_id',
    },
    {
        title: 'Student Name',
        dataIndex: 'std_name',
        key: 'std_name',
    },
    {
        title: 'Entry Time',
        dataIndex: 'entry_time',
        key: 'entry_time',
    },
    {
      title: 'Exit Time ',
      dataIndex: 'exit_time',
      key: 'exit_time',
    },
  ];

  type FieldType = {
    centre?: string;
    date?:string;
  }

function Viewattendance() {
    const [loading, setLoading] = useState(true);
    const [data, setElement] = useState();
    const [centreData, setCentreData] = useState([]);
    const [form] = Form.useForm();

    const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
          
        });
      }
    
    const loadAttend = ()=>{
        setLoading(true);
        let centre_code = form.getFieldValue('centre');
        let date = moment(form.getFieldValue('date').toString()).format('YYYY-MM-DD');
       //let date = '';
        getData('getattendancedata/'+centre_code+'/'+date).then((res)=>setElement(res.data));

        setLoading(false);
    }

    



    useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
       // console.log(centre_code);
        loadCentre();
        form.setFieldValue('centre',centre_code);
        form.setFieldValue('date',dayjs(Date().toString()));

        loadAttend();
       },[]);
  return (
    <div className='Viewattendance'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">View Attendance</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title={String(dayjs(Date()))} extra={
                        <>
                        <Form 
                         form={form} 
                         layout='inline' >
                            <Form.Item<FieldType> 
                       
                              name="centre"
                              >
                            <Select placeholder="Select Centre">
                                {
                                adm_type == 1 ? (
                                    centreData.map((item:any)=>{
                                        return(
                                                  <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                              
                                            )       
                                        })
                                    ) : (
                                        <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                                    )
                                }
                            
                            </Select>
                            </Form.Item>
                            <Form.Item  name="date">
                                <DatePicker className='ml-2' format={'DD-MM-YYYY'} onChange={loadAttend}/>
                            </Form.Item>
                        </Form>
                            
                        </>
                       
                      
                    } >
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={data} size="small" />
                        </Spin>
                    </Card>
                    </div>
                </div>
              </div>
            </section>
    </div>
  )
}

export default Viewattendance