import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useParams,useLocation } from 'react-router-dom'
import {getPagePer} from '../services/index';
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import dayjs from 'dayjs';
import { img_url } from '../services/index';
import { back_url } from '../services/index';

import './style.css';
import { aD } from '@fullcalendar/core/internal-common';
import axios from 'axios';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let user_id:any;



type FieldType = {
    centre?: string;
    stdlist?:string;
  }
const { Option } = Select;
let options: any=[];



interface DataType {
  sno:number;
  sid : number;
  receipt_no?: string;
  receipt_date?: string;
  tax_amt?: string;
  nontax_amt?: string;
  gst?: number;
  pay_type?: string;
  fee_type?: number;
  entry_by?: string;
  entry_time?: string;
  update_by?: string;
  update_time?:string;
  reason?:string;
  amount?:string;
  inst_id:number;
  status:number;
}


function Installments() {
    const [loading, setLoading] = useState(false);
    const [data, setElement] = useState();
    const [centreData, setCentreData] = useState([]);
    const [form] = Form.useForm();
    const [stdData, setStdData] = useState([]);
    const [std_id, setStdID]=useState("");
    const [std_name, setStdName]=useState("");
    const [adm_no, setAdmNo]=useState("");
    const [course_name, setCourseName]=useState("");
    const [img, setImg]=useState("");
    const [std_fee, setFee]=useState("");
    const [paid, setPaid]=useState("");
    const [due, setDue]=useState("");
    const [isHide, setIsHide] = useState(true);
    const [isChide, setChide] = useState(false);
    const location = useLocation();
    const m_id = location.state.id;
    const type = location.state.type;

    const [showPaymentBtn,setPaymentbtn] = useState(false);
    const [showPrintBtn,setPrintbtn] = useState(false);
    const [showDeleteBtn,setDeletebtn] = useState(false);

    const columns: ColumnsType<DataType> = [
      {
        title: '#',
        dataIndex: 'sno',
        key: 'sno',
      },
      {
        title: 'Reciept No.',
        dataIndex: 'receipt_no',
        key: 'receipt_no',
      },
      {
          title: 'Date',
          dataIndex: 'receipt_date',
          key: 'receipt_date',
      },
      {
          title: 'Tax Amount',
          dataIndex: 'tax_amt',
          key: 'tax_amt',
      },
      {
        title: 'Non Tax Amount',
        dataIndex: 'nontax_amt',
        key: 'nontax_amt',
      },
      {
        title: 'GST',
        dataIndex: 'gst',
        key: 'gst',
      },
      {
        title: 'Payment Mode',
        dataIndex: 'pay_type',
        key: 'pay_type',
      },
      {
        title: 'Fee Type',
        dataIndex: 'fee_type',
        key: 'fee_type',
        render: (_,{fee_type}) => fee_type==1? <Tag color="success">Admission</Tag> : fee_type==2? <Tag color="processing">Installment</Tag> : fee_type==3? <Tag color="warning">Diploma Fees</Tag> : <Tag color="error">Course Upgrade Fees</Tag>
    
      },
      {
        title: 'Entry By',
        dataIndex: 'staff_name',
        key: 'staff_name',
      },
      {
        title: 'Entry Time',
        dataIndex: 'entry_time',
        key: 'entry_time',
      },
      {
        title: 'Updated By',
        dataIndex: 'update_by',
        key: 'update_by',
      },
      {
        title: 'Update Time',
        dataIndex: 'update_time',
        key: 'update_time',
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
      },
      {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          fixed: 'right',
          width:100,
          render: (_,{amount,status}) => status==1? <Tag color="success"><h6> &#8377; {amount}</h6></Tag> :  <Tag color="error"><h6>&#8377; {amount}</h6></Tag>
    
      },
      
      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width:100,
        render: (_, {inst_id}) => (
            <> <Tooltip title="View/Print Receipt"> 
              <a href={back_url+'paymentreciept/'+inst_id} target='_blank' className={showPrintBtn?'':'hidden'} >
                <Button type="primary" size='small' className='mr-1'><i className="fas fa-eye"></i></Button>
              </a>
              </Tooltip>
    
              <Tooltip title="Delete Reciept">
                <Button type="primary" className={showDeleteBtn?'':'hidden'}  size='small' danger><i className="fas fa-trash"></i></Button>
              </Tooltip>
            
              
            </>
        ),
        
      },
    ];

    //console.log(location);
    const {id} = useParams();
    
   
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
      setOpen(true);
      form.setFieldValue('staff_code',staff_code);
      if(id!='0'){
        form.setFieldValue('std_id',atob(String(id)));
      }else{
        
        form.setFieldValue('std_id',std_id);
      }
      
    };

    const onClose = () => {
      setOpen(false);
    };

    const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
           
          setCentreData(res.data)
          
        });
      }
    
      const loadStdList = ()=>{
       // console.log(centre_code);
        getData('getStdlist/'+String(centre_code)).then((res)=>{
            options = res.data;
            setStdData(res.data);
            
           // console.log(stdData);
        })
      }

      const handleChange = (value: string) => {
        setIsHide(false);
        setLoading(true);
        getData('loadPaymentdata/'+String(value)).then((res)=>{
          setLoading(false);
          setStdID(res.data['res1'].std_id);
          setCourseName(res.data['res1'].course_name);
          setStdName(res.data['res1'].std_name);
          setAdmNo(res.data['res1'].adm_no);
          setImg(res.data['res1'].std_img);
          setFee(res.data['res1'].std_fee);
          setElement(res.data['res2']);

          let arr = res.data['res2'];
          let fee = res.data['res1'].std_fee
          let sum = 0;
          arr.forEach((element: any) => {
            if(element.status == 1){
              sum = sum+parseInt(element.amount);
            }
            
          });
          setPaid(String(sum));
          let due1 = parseInt(res.data['res1'].std_fee)-sum;
          //console.log(due1);
          setDue(String(due1));
          
       //   console.log(res.data['res1'].std_id);
        })
      };

      const handleCentreChange = (value: string) => {
        centre_code = value;
        loadStdList();
      }

      const onFormSubmit = useCallback(async () => {
        form
          .validateFields()
          .then(() => {
            // Validation is successful
            setLoading(true)
            let formData = form.getFieldsValue();
            postData('addinstallment',formData).then((res)=>{
           // console.log(res.data['success']);
            setLoading(false);
            if(res.data['success']==true){
              successMsg('Payment Save !!');
              onClose();
              sendMSG(res.data['std_name'],res.data['fee_amt'],res.data['tot_fees'],res.data['paid'],res.data['due'],res.data['date'],res.data['std_mobile']);
            }else{
              errorMsg("Error, Try Again!!")
            }

            handleChange(res.data['std_id']);
          })

          })
          .catch((errors) => {
            // Errors in the fields
            errorMsg("Error, Try Again!!")
            //errorMsg('Error Try Again');
          });
      }, [form]);

      const sendMSG = async (std_name: any, fee_amt:any,tot_fees:any,paid:any,due:any, date:any, mobile:any) => {
      
          var message = encodeURI("Aranyak Institute \nHi,"+ std_name + " thank you for paying Rs" + fee_amt +" \nDate:"+ date +"\nTotal fees: " +tot_fees+" \nPaid:" + paid+" \nDue: "+ due) ;
      
  
       await axios.get("https://web.cloudwhatsapp.com/wapp/api/send?apikey=a3f421f32a564cc897a22df74374ee7e&mobile="+ mobile +"&msg=" + message).then((res)=>{
        console.log(res);
         return true;
       })
    }

    useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
       // console.log(login);
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code;
        user_id = login.user_id;
       // console.log(centre_code);
        getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res)=>{
          for(let i=0;i<res.data.length;i++){
            switch(parseInt(res.data[i].per_element_id)){
              case 1:
                    break;
              case 6:
                    setPaymentbtn(true);
                    break;
              case 7:
                    setPrintbtn(true);
                    break;
              case 8:
                    setDeletebtn(true);
                    break;
            }
          }
        })
        loadCentre();
        form.setFieldValue('centre',centre_code);
        form.setFieldValue('date',dayjs(Date().toString()));
        loadStdList();

        if(id != '0'){
          handleChange(atob(String(id)));
          setChide(true);
        }
        //alert("std_id: " + id + " m_id: "+ m_id );

        //alert(m_id);
        //loadAttend();
       },[]);
    
  return (
    <>
    <div className='Installments'>
        <div className="content-header mt-5">
          <div className={isChide? 'hidden':''}>
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Installments</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
              
             
              <div className="row mb-2">
               
                <div className=" col-sm-12 d-flex">
                <Form form={form} layout='inline'>
                   <Form.Item<FieldType> 
                       
                       name="centre"
                       className="d-flex"
                       >
                     <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                         {
                         adm_type == 1 ? (
                             centreData.map((item:any)=>{
                                 return(
                                           <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                       
                                     )       
                                 })
                             ) : (
                                 <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                             )
                         }
                     
                     </Select>
                     </Form.Item>

                     <Form.Item<FieldType> 
                       
                       name="stdlist"
                       
                       >
                     <Select placeholder="Select Name"    className='flex-fill' 
                        
                        showSearch
                        style={{ width: 400 }}
                        onChange={handleChange}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                        }
                        options={options}

                     >
                     
                     </Select>
                     </Form.Item>
                     </Form>
                </div>{/* /.col */}
               
                
              </div>{/* /.row */} 
            </div>{/* /.container-fluid */}
            </div>
          </div>
          <section className="content" >
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                        <Spin tip="Loading" size="large" spinning={loading}>
                            <Card className={isHide?'hidden':''} title="Student Payment Detail" extra={<Button type='primary' className={showPaymentBtn?'':'hidden'} onClick={showDrawer}>Add Payment</Button>}>
                              <div className={isChide?'hidden':''}>
                              <div className="row" >
                                    <div className="col">
                                        <img src={img_url+img} alt="" className="img-thumbnail" width="100" height="100" />
                                    </div>
                                    <div className="col">
                                        <h5>Student ID : {std_id} </h5><br/>
                                        <h5>Name : {std_name}</h5>

                                    </div>
                                    <div className="col">
                                        <h5>Course : {course_name}</h5><br/>
                                        <h5>Adm No : {adm_no}</h5>
                                    </div>
                                </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <div className="card-counter info">
                                            <i className="fas fa-coins"></i>
                                            <span className="count-numbers" id="total_amt">&#8377; {std_fee}</span>
                                            <span className="count-name">Total Amount</span>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card-counter success">
                                            <i className="fas fa-check-circle"></i>
                                            <span className="count-numbers" id="total_paid">&#8377; {paid}</span>
                                            <span className="count-name">Paid</span>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card-counter danger">
                                            <i className="fas fa-exclamation-circle"></i>
                                            <span className="count-numbers" id="total_due">&#8377; {due}</span>
                                            <span className="count-name">Due</span>
                                        </div> 
                                    </div>
                                </div>
                                <Table className='font-style' columns={columns} dataSource={data} scroll={{ x: 2000 }} size="small" />
                            </Card>
                        </Spin>
                    
                    </div>
                </div>
              </div>
            </section>


            
          
    </div>
    <Drawer
        title="Payment Reciept Entry"
        width={520}
        onClose={onClose}
        open={open}
        zIndex={1000}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onFormSubmit} type="primary">
              Submit
            </Button>
          </Space>
          
        }
        style={{
          marginTop:50,
        }}
        
      >
      <Spin tip="Loading" size="large" spinning={loading}>
        <Form layout="vertical"  form={form} autoComplete="off">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="std_id"
                label="Student ID"
                rules={[{ required: true, message: 'Please enter user name' }]}
              >
                <Input placeholder="Please enter user name" readOnly/>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="fee_amt"
                label="Amount"
                rules={[{ required: true, message: 'Please enter amount' }]}
              >
                <Input placeholder="Please enter amount"  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="pay_type"
                label="Choose Payment Mode"
                rules={[{ required: true, message: 'Please select an Payment Mode' }]}
              >
                <Select placeholder="Please select Payment Mode" >
                  <Option value="Cash">Cash</Option>
                  <Option value="Google Pay">Google Pay</Option>
                </Select>
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="fee_type"
                label="Choose Payment For"
                rules={[{ required: true, message: 'Please choose Payment For' }]}
              >
                <Select placeholder="Please choose Payment For">
                  <Option value="1">Admission</Option>
                  <Option value="2">Installment</Option>
                  <Option value="3">Diploma Fees</Option>
                  <Option value="4">Course Upgrade Fees</Option>
                 
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="staff_code"
                label="Staff Code"
                rules={[{ required: true, message: 'Please enter Staff Code' }]}
              >
                <Input placeholder="Please enter Staff Code" disabled />
              </Form.Item>
            </Col>
            
          </Row>
        </Form>
        </Spin>
      </Drawer>
    </>


  )
}

export default Installments